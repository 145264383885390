import { AxiosResponse } from "axios";
import {
  NewProductRequest,
  Product,
  ProductSuggestNameResponse,
} from "../../models/Product";
import api from "../api";

const productsService = {
  create: async (data: NewProductRequest): Promise<AxiosResponse<Product>> => {
    const response: AxiosResponse<Product> = await api.post<Product>(
      "products",
      data
    );

    return response;
  },
  findAll: async (): Promise<AxiosResponse<Product[]>> => {
    const response: AxiosResponse<Product[]> = await api.get<Product[]>(
      "products"
    );

    return response;
  },
  findById: async (productId: string): Promise<AxiosResponse<Product>> => {
    const response: AxiosResponse<Product> = await api.get<Product>(
      `products/${productId}`
    );

    return response;
  },

  findByBarcode: async (barcode: string): Promise<AxiosResponse<Product>> => {
    const response: AxiosResponse<Product> = await api.get<Product>(
      `products/barcode/${barcode}`
    );

    return response;
  },

  update: async (
    productId: string,
    data: Partial<Pick<Product, "pictureUrl" | "barcode">>
  ): Promise<AxiosResponse<Product>> => {
    const response: AxiosResponse<Product> = await api.put<Product>(
      `products/${productId}`,
      data
    );

    return response;
  },

  suggestNameBasedOnBarcode: async (
    barcode: string
  ): Promise<AxiosResponse<ProductSuggestNameResponse>> => {
    const response: AxiosResponse<ProductSuggestNameResponse> =
      await api.get<ProductSuggestNameResponse>(
        `products/suggest-name/${barcode}`
      );

    return response;
  },
};

export default productsService;
