import { AxiosResponse } from "axios";
import { UserLoginRequest, UserLoginResponse } from "../../models/User";
import api from "../api";

const authService = {
  login: async (
    data: UserLoginRequest
  ): Promise<AxiosResponse<UserLoginResponse>> => {
    const response: AxiosResponse<UserLoginResponse> =
      await api.post<UserLoginResponse>("auth/login", data);

    return response;
  },
};

export default authService;
