import * as yup from "yup";

const newStockSchema = yup.object({
  company: yup.string().required("Informe o id da empresa"),
  product: yup.string().required("Informe o id do produto"),
  quantity: yup
    .number()
    .required("Informe a quantidade")
    .min(1, "A quantidade mínima deve ser 1"),
});

export default newStockSchema;
