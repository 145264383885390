import { extendTheme } from "@chakra-ui/react";

const themeProperties = {
  initialColorMode: "dark",
  useSystemColorMode: false,
  fonts: {
    heading: `'Inter', sans-serif`,
    body: `'Inter', sans-serif`,
  },
  fontSizes: {
    xs: "0.625rem", // 10px ok
    sm: "0.75rem", // 12px ok
    md: "0.875rem", // 14px ok
    lg: "1rem", //16px
    xl: "1.125rem", //18px
    "2xl": "1.25rem", //20px
    "3xl": "1.5rem", //24px
    "4xl": "1.75rem", //28px
    "5xl": "2rem", //32px
    "6xl": "2.25rem", //36px
    "7xl": "2.5rem", //40px
    "8xl": "3rem", //48px
    "9xl": "3.75srem", //60px
  },
  lineHeights: {
    normal: "normal",
    none: 1,
    shorter: 1.25,
    short: 1.375,
    base: 1.5,
    tall: 1.625,
    taller: "2",
    "3": "1rem", //16px
    "4": "1.125rem", //18px
    "5": "1.25rem", //20px
    "6": "1.5rem", //24px
    "7": "2.25rem", //36px ok
    "8": "2.375rem", //38px ok
    "9": "3rem", //48px ok
    "10": "3.5rem", //56px ok
  },
  colors: {
    primary: {
      50: "#fbeaea",
      100: "#f6d8d8",
      200: "#f2c5c6",
      300: "#eaa0a1",
      400: "#da5657",
      500: "#ce1f20",
      600: "#b62324",
      700: "#a02627",
      800: "#8b2828",
      900: "#522626",
    },
    secondary: {
      50: "#feefd7",
      100: "#fee4b9",
      200: "#fde1b1",
      300: "#fcc366",
      400: "#fbb540",
      500: "#faa61a",
      600: "#d79322",
      700: "#c27700",
      800: "#a77829",
      900: "#7c5e2b",
    },
    success: {
      50: "#e8f0e4",
      100: "#d4e3cb",
      200: "#c0d6b2",
      300: "#82ae67",
      400: "#45861d",
      500: "#307904",
      600: "#2d6b08",
      700: "#2a5d0c",
      800: "#27500e",
      900: "#234410",
    },
    info: {
      50: "#e9f2fd",
      100: "#d6e6fa",
      200: "#afcef6",
      300: "#76aaef",
      400: "#3c87e9",
      500: "#156fe4",
      600: "#1b67c9",
      700: "#205fb0",
      800: "#235698",
      900: "#26456d",
    },
    warning: {
      50: "#fef2e9",
      100: "#fee7d6",
      200: "#fdcfb0",
      300: "#fcb889",
      400: "#fa8a3c",
      500: "#f97316",
      600: "#dc6b1d",
      700: "#c06322",
      800: "#a65a26",
      900: "#8e5228",
    },
    error: {
      50: "#f8e7ea",
      100: "#f1d2d8",
      200: "#e4a8b3",
      300: "#d1697c",
      400: "#b71532",
      500: "#b00020",
      600: "#9b0722",
      700: "#870c23",
      800: "#741123",
      900: "#521621",
    },
    gray: {
      50: "#f2f2f2",
      100: "#e6e6e6",
      200: "#cdcdcd",
      300: "#bbbbbb",
      400: "#9a9a9a",
      500: "#818181",
      600: "#686868",
      700: "#494949",
      800: "#393939",
      900: "#282828",
    },
  },
};
const theme = extendTheme(themeProperties);

export { theme, themeProperties };
