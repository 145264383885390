import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Image,
  Input,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Product, UpdateProductRequest } from "../../../core/models/Product";
import productsService from "../../../core/services/products";
import { setLoader } from "../../../core/stores/reducers/Loader";
import Alert from "../../../core/utils/Alert";

import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";

import { ActualFileObject } from "filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import { FilePond, registerPlugin } from "react-filepond";
import Swal from "sweetalert2";
import uploadsService from "../../../core/services/upload";
import updateProductSchema from "../../../core/validations/updateProduct";

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

export default function ProductDetail() {
  const { productId } = useParams();
  const filepondRef = useRef<FilePond>(null);

  const dispatch = useDispatch();
  const [product, setProduct] = useState<Product | null>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [showFilePondForm, setShowFilePondForm] = useState<boolean>(false);

  const { control, handleSubmit, reset, setValue, getValues } =
    useForm<UpdateProductRequest>({
      resolver: yupResolver(updateProductSchema),
      mode: "all",
    });

  const handleGetProductDetails = useCallback(() => {
    if (productId) {
      dispatch(
        setLoader({
          isLoading: true,
          text: "Obtendo dados do produto",
        })
      );

      productsService
        .findById(productId)
        .then(({ data }) => {
          setProduct(data);
        })
        .catch(() => {
          Alert.danger({
            message: "Houve um erro ao tentar buscar as informações do produto",
          });
        })
        .finally(() => {
          dispatch(
            setLoader({
              isLoading: false,
            })
          );
        });
    }
  }, [dispatch, productId]);

  const handleSaveBarcode = handleSubmit(async (data: UpdateProductRequest) => {
    if (productId) {
      const dialogResponse = await Swal.fire({
        title:
          "Você tem certeza que deseja adicionar este código de barras a este produto?",
        text: "Não será possível reverter esta operação.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eu quero continuar!",
        cancelButtonText: "Não, eu quero desistir!",
      });

      if (dialogResponse.isConfirmed) {
        dispatch(
          setLoader({
            isLoading: true,
            text: "Atualizando produto...",
          })
        );

        productsService
          .update(productId, data)
          .then(() => {
            Alert.success({
              message: "Produto atualizado com sucesso",
            });
            handleGetProductDetails();
          })
          .catch(() => {
            Alert.danger({
              message: "Falha ao tentar atualizar o produto",
            });
          })
          .finally(() => {
            dispatch(
              setLoader({
                isLoading: false,
              })
            );
          });
      }
    }
  });

  const handleSeeOnline = (barcode: string) => {
    const url = `${process.env.REACT_APP_COSMOS_BLUESOFT_BASE_URL}produtos/${barcode}`;
    window.open(url, "_blank");
  };

  const handleUploadFile = useCallback(
    async (file: ActualFileObject) => {
      if (product && productId) {
        dispatch(
          setLoader({
            isLoading: true,
            text: "Enviando sua imagem. Aguarde...",
          })
        );

        try {
          const uploadsServiceResponse = await uploadsService.sendNewFile(file);

          const { url } = uploadsServiceResponse.data;

          const data: Pick<Product, "pictureUrl"> = { pictureUrl: url };

          await productsService.update(productId, data);

          const productResponse = await productsService.findById(productId);

          const { data: productData } = productResponse;

          setProduct(productData);

          Alert.success({
            message: "Foto atualizada com sucesso",
          });
        } catch (e) {
          Alert.danger({
            message: "Ocorreu um erro ao tentar atualizar a foto do produto",
          });
        } finally {
          dispatch(
            setLoader({
              isLoading: false,
            })
          );

          setShowFilePondForm(false);
        }
      }
    },
    [dispatch, product, productId]
  );

  const handleGoBack = () => {
    if (location.state?.from) {
      navigate(-1);
    } else {
      navigate("/products/list");
    }
  };

  useEffect(() => {
    handleGetProductDetails();
  }, [handleGetProductDetails]);

  return (
    <>
      <Container maxW={"full"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Detalhes do produto
          </Heading>
          {product && (
            <HStack
              maxW={"800px"}
              w={"full"}
              boxShadow={"2xl"}
              borderRadius={"lg"}
              overflow={"hidden"}
              p={6}
              my={6}
              mx={"auto"}
              spacing={4}
              bg="white"
              alignItems="flex-start"
            >
              <Box flexShrink={0}>
                {showFilePondForm ? (
                  <FormControl as="fieldset">
                    <FormLabel as="label">Foto do produto:</FormLabel>
                    <FilePond
                      allowMultiple={false}
                      maxFiles={1}
                      labelIdle='Arraste e solte um arquivo ou <span class="filepond--label-action">clique aqui para procurar</span>'
                      onaddfile={(_, item) => {
                        handleUploadFile(item.file);
                      }}
                      onremovefile={() => {
                        setShowFilePondForm(false);
                      }}
                      ref={filepondRef}
                      allowImagePreview
                      acceptedFileTypes={["image/png", "image/jpeg"]}
                      maxFileSize="2MB"
                    />
                    <FormHelperText>
                      Você pode enviar aquivos do tipo <b>.png</b> ou{" "}
                      <b>.jpg</b>.
                    </FormHelperText>
                  </FormControl>
                ) : (
                  <Image
                    src={
                      product.pictureUrl ??
                      require("../../../core/assets/noPicture.jpeg")
                    }
                    alt={product.name}
                    boxSize="250px"
                    borderRadius={product.pictureUrl ? "md" : "full"}
                    objectFit="cover"
                  />
                )}
                <Button
                  mt={4}
                  onClick={() => {
                    setShowFilePondForm(!showFilePondForm);
                  }}
                >
                  {showFilePondForm ? "Cancelar" : "Alterar imagem"}
                </Button>
              </Box>

              <VStack align="start" spacing={2}>
                <Heading fontSize="2xl">{product.name}</Heading>
                <Text fontSize="sm" color="gray.500">
                  ID:{" "}
                  <Text as="span" fontWeight="semibold">
                    {product._id}
                  </Text>
                </Text>
                <Text fontSize="sm" color="gray.500">
                  Data criação:{" "}
                  <Text as="span" fontWeight="semibold">
                    {moment(product.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                  </Text>
                </Text>
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"flex-start"}
                  minWidth={"full"}
                >
                  <Box>
                    <Text fontSize="sm" color="gray.500">
                      Código de barras:{" "}
                      {product.barcode && (
                        <>
                          <Text as="span" fontWeight="semibold">
                            {product.barcode}
                          </Text>
                          <Badge
                            cursor={"pointer"}
                            ml={2}
                            variant="outline"
                            colorScheme="green"
                            onClick={() => {
                              handleSeeOnline(product.barcode);
                            }}
                          >
                            Ver no catálogo on-line
                          </Badge>
                        </>
                      )}
                    </Text>
                  </Box>
                  <Box width={"full"} minWidth={"full"}>
                    {!product.barcode && (
                      <form
                        onSubmit={handleSaveBarcode}
                        style={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormControl id="barcode">
                          <Controller
                            control={control}
                            name="barcode"
                            render={({
                              field: { onChange, onBlur, value },
                            }) => (
                              <Input
                                type="barcode"
                                placeholder="Digite aqui o código de barras do produto"
                                value={value || ""}
                                onChange={(event) => {
                                  onChange(
                                    event.target.value
                                      .toUpperCase()
                                      .replace(/\D/g, "")
                                  );
                                }}
                                autoComplete="off"
                                required
                                autoFocus
                              />
                            )}
                          />
                        </FormControl>
                        <Button
                          bg={"primary.400"}
                          color={"white"}
                          _hover={{
                            bg: "primary.500",
                          }}
                          type="submit"
                          ml={2}
                        >
                          Adicionar
                        </Button>
                      </form>
                    )}
                  </Box>
                </Box>

                <Box
                  display={"flex"}
                  alignItems={"flex-start"}
                  flexDirection={"column"}
                >
                  <Text fontSize="sm" color="gray.500">
                    Hash único:{" "}
                  </Text>
                  <Badge colorScheme="blue" px={2} py={1}>
                    #{product.hash}
                  </Badge>
                </Box>
              </VStack>
            </HStack>
          )}

          <Center>
            <Button
              bg={"primary.400"}
              color={"white"}
              _hover={{
                bg: "primary.500",
              }}
              maxWidth={"100px"}
              onClick={handleGoBack}
            >
              Voltar
            </Button>
          </Center>
        </Stack>
      </Container>
    </>
  );
}
