import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import jwtDecode from "jwt-decode";
import { useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserLoginRequest, UserTokenDecoded } from "../core/models/User";
import authService from "../core/services/auth";
import { setLoader } from "../core/stores/reducers/Loader";
import { setSession } from "../core/stores/reducers/Session";
import Alert from "../core/utils/Alert";
import userLoginSchema from "../core/validations/userLogin";

function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const captcha = useRef<ReCAPTCHA>({} as ReCAPTCHA);

  const { control, handleSubmit, formState, setValue } =
    useForm<UserLoginRequest>({
      resolver: yupResolver(userLoginSchema),
      mode: "all",
    });

  const { isValid } = formState;

  const login = handleSubmit((data: UserLoginRequest) => {
    dispatch(
      setLoader({
        isLoading: true,
      })
    );
    authService
      .login(data)
      .then((response) => {
        const { data } = response;
        const decoded: UserTokenDecoded = jwtDecode(data.token);

        dispatch(
          setSession({
            token: data.token,
            user: decoded.sub,
          })
        );

        navigate("/home");
      })
      .catch(() => {
        Alert.danger({
          message: "Ocorreu um erro ao tentar realizar o login",
        });
        setValue("password", "");
        captcha.current.reset();
      })
      .finally(() => {
        dispatch(
          setLoader({
            isLoading: false,
          })
        );
      });
  });

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Stack align={"center"}>
          <Box
            justifyContent={"center"}
            alignItems="center"
            display={"flex"}
            mb={10}
          />

          <Heading fontSize={"4xl"}>
            {process.env.REACT_APP_MAXIMO_SYSTEM_NAME}
          </Heading>
          <Text>Informe suas credenciais para acessar</Text>
        </Stack>
        <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
          <Stack spacing={4}>
            <FormControl id="email">
              <Controller
                control={control}
                name="email"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    type="email"
                    placeholder="Seu Email"
                    value={value || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControl>
            <FormControl id="password">
              <Controller
                control={control}
                name="password"
                render={({ field: { onChange, onBlur, value } }) => (
                  <Input
                    type="password"
                    placeholder="Sua senha"
                    value={value || ""}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
              />
            </FormControl>
            <FormControl id="recaptcha">
              <Controller
                control={control}
                name="recaptcha"
                render={({ field: { onChange } }) => (
                  <ReCAPTCHA
                    sitekey={String(
                      process.env.REACT_APP_GOOGLE_RECAPTCHA_V2_KEY
                    )}
                    onChange={onChange}
                    ref={captcha}
                  />
                )}
              />
            </FormControl>

            <Stack spacing={10}>
              <Button
                bg={"primary.400"}
                color={"white"}
                _hover={{
                  bg: "primary.500",
                }}
                isDisabled={!isValid}
                onClick={login}
              >
                Entrar
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Flex>
  );
}

export default Login;
