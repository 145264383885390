import {
  Box,
  Button,
  Container,
  FormControl,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Product } from "../../../core/models/Product";
import { NewStockRequest } from "../../../core/models/Stock";
import { UserCompany } from "../../../core/models/User";
import productsService from "../../../core/services/products";
import stocksService from "../../../core/services/stocks";
import usersService from "../../../core/services/user";
import { setLoader } from "../../../core/stores/reducers/Loader";
import { selectSession } from "../../../core/stores/reducers/Session";
import Alert, { timer } from "../../../core/utils/Alert";
import useQuery from "../../../core/utils/useQuery";
import newStockSchema from "../../../core/validations/newStock";
import Select from "../../../layout/components/Select";

type StockIntakeProps = {
  type: "INTAKE" | "OUTPUT";
};

type StockIntakeMode = "barcode" | null;

export default function StockIntake({ type }: StockIntakeProps) {
  const [userCompanies, setUserCompanies] = useState<UserCompany[]>(
    [] as UserCompany[]
  );
  const [productByBarcode, setProductByBarcode] = useState<Product | null>(
    null
  );
  const [products, setProducts] = useState<Product[]>([] as Product[]);
  const [barcode, setBarcode] = useState<string>("");

  const query = useQuery();
  const mode = query.get("mode") as StockIntakeMode;
  const isBarcodeMode = mode === "barcode";

  const inputBarcodeRef = useRef<HTMLInputElement | null>(
    {} as HTMLInputElement
  );

  const companiesOptions = userCompanies.map(({ company: { name, _id } }) => ({
    label: name,
    value: _id,
  }));

  const productsOptions = products.map(({ name, _id, barcode }) => ({
    label: `${name}${barcode ? " | " + barcode : ""}`,
    value: _id,
  }));

  const dispatch = useDispatch();

  const { user } = useSelector(selectSession);

  const { control, handleSubmit, formState, setValue, watch, reset } =
    useForm<NewStockRequest>({
      resolver: yupResolver(newStockSchema),
      mode: "all",
    });

  const { isValid } = formState;

  const companyField = watch("company");

  const handleChangeBarcode = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBarcode(event.target.value.replace(/\D/g, ""));
  };

  const clearInput = () => {
    setBarcode("");
  };

  const handleKeyDownBarcode = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === "Enter" && barcode.trim().length > 0) {
        dispatch(
          setLoader({
            isLoading: true,
            text: "Buscando produto pelo código de barras",
          })
        );

        productsService
          .findByBarcode(barcode)
          .then(({ data }) => {
            setProductByBarcode(data);
          })
          .catch((e) => {
            console.log(e);
            Alert.danger({
              message:
                "Não foi possível localizar o produto com o código informado",
            });
          })
          .finally(() => {
            dispatch(
              setLoader({
                isLoading: false,
                text: undefined,
              })
            );
          });
      }
    },
    [barcode, dispatch]
  );

  const findCompaniesAndProducts = useCallback(async () => {
    if (user) {
      dispatch(
        setLoader({
          isLoading: true,
        })
      );
      Promise.all([
        usersService.findAllCompanies(user._id),
        productsService.findAll(),
      ])
        .then(([response1, response2]) => {
          const { data: companiesData } = response1;
          const { data: productsData } = response2;

          setUserCompanies(companiesData);
          setProducts(productsData);
        })
        .finally(() => {
          dispatch(
            setLoader({
              isLoading: false,
            })
          );
        });
    }
  }, [user, dispatch]);

  const create = handleSubmit((data: NewStockRequest) => {
    dispatch(
      setLoader({
        isLoading: true,
      })
    );

    if (type === "INTAKE") {
      data.quantity = Math.abs(data.quantity);
    } else {
      data.quantity = Math.abs(data.quantity) * -1;
    }

    stocksService
      .create(data)
      .then(() => {
        Alert.success({
          message: `${
            type === "INTAKE" ? "Entrada" : "Saída"
          } de produto no estoque realizada com sucesso`,
          autoClose: isBarcodeMode,
        });

        setValue("product", "");
        setValue("quantity", 0);

        if (isBarcodeMode) {
          if (companyField) {
            setBarcode("");
            setTimeout(() => {
              inputBarcodeRef.current?.focus();
            }, timer + 100);
          }
        } else {
          setValue("company", "");
          reset();
        }
      })
      .catch((error) => {
        Alert.danger({
          message: `Ocorreu um erro ao tentar realizar a ${
            type === "INTAKE" ? "Entrada" : "Saída"
          } de produto no estoque`,
        });
      })
      .finally(() => {
        dispatch(
          setLoader({
            isLoading: false,
          })
        );
      });
  });

  useEffect(() => {
    findCompaniesAndProducts();
  }, [findCompaniesAndProducts]);

  useEffect(() => {
    if (productByBarcode) {
      setValue("product", productByBarcode?._id);
    } else {
      setValue("product", "");
      setValue("quantity", 0);
    }
  }, [productByBarcode, setValue]);

  useEffect(() => {
    if (!barcode) setProductByBarcode(null);
  }, [barcode]);

  return (
    <>
      <Container maxW={"3xl"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            {type === "INTAKE" ? "Entrada" : "Saída"}{" "}
            {isBarcodeMode ? "pelo cód. barras" : "manual"}
            <br />
            <Text as={"span"} color={"primary.400"}>
              de produtos
            </Text>
          </Heading>

          <form onSubmit={create}>
            <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
              <Stack spacing={4}>
                <FormControl id="company">
                  <Controller
                    control={control}
                    name="company"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        options={companiesOptions}
                        placeholder="Selecione uma empresa"
                        value={companiesOptions.find(
                          (company) => company.value === value
                        )}
                        onChange={(value) => {
                          onChange(value?.value ?? null);
                          if (isBarcodeMode && companyField) {
                            setTimeout(() => {
                              if (inputBarcodeRef && inputBarcodeRef.current)
                                inputBarcodeRef?.current?.focus();
                            }, 1000);
                          }
                        }}
                        required
                        onBlur={onBlur}
                      />
                    )}
                  />
                </FormControl>
                {isBarcodeMode ? (
                  companyField ? (
                    <InputGroup>
                      <Input
                        type="string"
                        placeholder={"Passe o leitor de cód. de barras aqui..."}
                        value={barcode}
                        onChange={handleChangeBarcode}
                        onKeyDown={handleKeyDownBarcode}
                        required
                        autoFocus
                        ref={inputBarcodeRef}
                      />
                      {barcode && (
                        <InputRightElement width="4.5rem">
                          <Button h="1.75rem" size="sm" onClick={clearInput}>
                            X
                          </Button>
                        </InputRightElement>
                      )}
                    </InputGroup>
                  ) : (
                    <></>
                  )
                ) : (
                  <FormControl id="product">
                    <Controller
                      control={control}
                      name="product"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <>
                          <Select
                            options={productsOptions}
                            placeholder="Selecione um produto"
                            value={productsOptions.find(
                              (product) => product.value === value
                            )}
                            onChange={(value) => {
                              onChange(value?.value ?? null);
                            }}
                            required
                            onBlur={onBlur}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                )}

                {isBarcodeMode && productByBarcode && (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    width={"full"}
                    alignItems={"flex-start"}
                    ml={2}
                  >
                    <Text fontSize="sm" color="gray.500" mb={1}>
                      Produto:{" "}
                      <Text as="span" fontWeight="semibold">
                        {productByBarcode.name}
                      </Text>
                    </Text>
                    <Text fontSize="sm" color="gray.500" mb={1}>
                      ID:{" "}
                      <Text as="span" fontWeight="semibold">
                        {productByBarcode._id}
                      </Text>
                    </Text>
                    <Text fontSize="sm" color="gray.500" mb={1}>
                      Data criação:{" "}
                      <Text as="span" fontWeight="semibold">
                        {moment(productByBarcode.createdAt).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </Text>
                    </Text>

                    <Image
                      src={
                        productByBarcode.pictureUrl ??
                        require("../../../core/assets/noPicture.jpeg")
                      }
                      alt={productByBarcode.name}
                      boxSize="75px"
                      borderRadius={productByBarcode.pictureUrl ? "md" : "full"}
                      objectFit="cover"
                      mt={2}
                    />
                  </Box>
                )}

                {((isBarcodeMode && productByBarcode) || !isBarcodeMode) && (
                  <FormControl id="quantity">
                    <Controller
                      control={control}
                      name="quantity"
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Input
                          type="number"
                          placeholder={`Quantidade a ${
                            type === "INTAKE" ? "entrar" : "sair"
                          }`}
                          value={value || ""}
                          onChange={(event) => {
                            onChange(event.target.value.replace(/\D/g, ""));
                          }}
                          onBlur={onBlur}
                          min={1}
                          autoFocus={isBarcodeMode}
                          required
                        />
                      )}
                    />
                  </FormControl>
                )}
                <Stack spacing={10}>
                  <Button
                    bg={"primary.400"}
                    color={"white"}
                    _hover={{
                      bg: "primary.500",
                    }}
                    isDisabled={!isValid}
                    type="submit"
                  >
                    Salvar {type === "INTAKE" ? "entrada" : "saída"}
                  </Button>
                </Stack>
              </Stack>
            </Box>
          </form>
        </Stack>
      </Container>
    </>
  );
}
