import {
  ChevronDownIcon,
  ChevronRightIcon,
  CloseIcon,
  HamburgerIcon,
} from "@chakra-ui/icons";
import {
  Avatar,
  Box,
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { selectSession, setSession } from "../../core/stores/reducers/Session";

export default function WithSubnavigation() {
  const { isOpen, onToggle } = useDisclosure();

  const dispatch = useDispatch();
  const { user } = useSelector(selectSession);
  const handleLogout = () => {
    dispatch(
      setSession({
        token: undefined,
        user: undefined,
      })
    );
  };

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <a href="/home">
            <Text
              textAlign={useBreakpointValue({ base: "center", md: "left" })}
              color={useColorModeValue("gray.800", "white")}
            >
              {process.env.REACT_APP_MAXIMO_SYSTEM_NAME}
            </Text>
          </a>

          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
          <a href="/user">
            <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
              <Avatar
                name={user?.name}
                mr={2}
                backgroundColor={"purple.500"}
                color={"white"}
                size={"sm"}
              />
              <Text
                textAlign={useBreakpointValue({ base: "center", md: "left" })}
                color={useColorModeValue("gray.800", "white")}
                fontSize={"sm"}
                as={"u"}
              >
                {user?.name.split(" ")[0]}
              </Text>
            </Box>
          </a>
          <Button
            as={"a"}
            fontSize={"sm"}
            fontWeight={400}
            variant={"link"}
            href={"#"}
            onClick={handleLogout}
          >
            Sair
          </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");
  const popoverContentBgColor = useColorModeValue("white", "gray.800");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Box
                as="a"
                p={2}
                href={navItem.href ?? "#"}
                fontSize={"sm"}
                fontWeight={500}
                color={linkColor}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor,
                }}
              >
                {navItem.label}
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={"xl"}
                bg={popoverContentBgColor}
                p={4}
                rounded={"xl"}
                minW={"sm"}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel }: NavItem) => {
  return (
    <Box
      as="a"
      href={href}
      role={"group"}
      display={"block"}
      p={2}
      rounded={"md"}
      _hover={{ bg: useColorModeValue("red.50", "gray.900") }}
    >
      <Stack direction={"row"} align={"center"}>
        <Box>
          <Text
            transition={"all .3s ease"}
            _groupHover={{ color: "red.400" }}
            fontWeight={500}
          >
            {label}
          </Text>
          <Text fontSize={"sm"}>{subLabel}</Text>
        </Box>
        <Flex
          transition={"all .3s ease"}
          transform={"translateX(-10px)"}
          opacity={0}
          _groupHover={{ opacity: "100%", transform: "translateX(0)" }}
          justify={"flex-end"}
          align={"center"}
          flex={1}
        >
          <Icon color={"red.400"} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      p={4}
      display={{ md: "none" }}
    >
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Box
        py={2}
        as="a"
        href={href ?? "#"}
        justifyContent="space-between"
        alignItems="center"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Box>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={"solid"}
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align={"start"}
        >
          {children &&
            children.map((child) => (
              <Box as="a" key={child.label} py={2} href={child.href}>
                {child.label}
              </Box>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "Empresa",
    children: [
      {
        label: "Nova empresa",
        subLabel: "Crie uma nova empresa para gerenciar",
        href: "/company/new",
      },
      {
        label: "Listar",
        subLabel: "Liste as empresas disponíveis",
        href: "/company/list",
      },
    ],
  },
  {
    label: "Nota fiscal",
    children: [
      {
        label: "Importar arquivo .xml",
        subLabel: "Importe uma nota gerada pelo fornecedor",
        href: "/invoice/import",
      },
      {
        label: "Relatório de importação",
        subLabel: "Visualize relatórios de importação de todas as NFes",
        href: "/invoice/report",
      },
      {
        label: "Pesquisar NF",
        subLabel: "Pesquise dados de uma NF pela chave",
        href: "/invoice/search",
      },
    ],
  },
  {
    label: "Estoque",
    children: [
      {
        label: "Extrato",
        subLabel: "Exibe todos o extrato de entrada e saída",
        href: "/stock/products",
      },
      {
        label: "Entrada via cód. barras",
        subLabel: "Realize a entrada de produtos via cód. de barras",
        href: "/stock/intake?mode=barcode",
      },
      {
        label: "Saída via cód. barras",
        subLabel: "Realize a saída de produtos via cód. de barras",
        href: "/stock/output?mode=barcode",
      },
      {
        label: "Entrada manual",
        subLabel: "Realize a entrada manual de produtos",
        href: "/stock/intake",
      },
      {
        label: "Saída manual",
        subLabel: "Realize a saída manual de produtos",
        href: "/stock/output",
      },
    ],
  },
  {
    label: "Produto",
    children: [
      {
        label: "Novo",
        subLabel: "Crie um novo produto independente",
        href: "/products/new",
      },
      {
        label: "Listar",
        subLabel: "Liste os produtos cadastrados",
        href: "/products/list",
      },
    ],
  },
  {
    label: "Usuários",
    children: [
      {
        label: "Listar",
        subLabel: "Liste os usuários cadastrados",
        href: "/user/list",
      },
      {
        label: "Novo",
        subLabel: "Cadastre um novo usuário",
        href: "/user/new",
      },
    ],
  },
];
