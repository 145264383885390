import { combineReducers } from "redux";

import loader from "./Loader";
import session from "./Session";

const rootReducer = combineReducers({
  loader,
  session,
});

export default rootReducer;
