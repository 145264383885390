import { AxiosResponse } from "axios";
import { Invoice, NewInvoiceRequest } from "../../models/Invoice";
import api from "../api";

const invoicesService = {
  create: async (data: NewInvoiceRequest): Promise<AxiosResponse<Invoice>> => {
    const response: AxiosResponse<Invoice> = await api.post<Invoice>(
      "invoices",
      data
    );

    return response;
  },
  findByKey: async (key: string): Promise<AxiosResponse<Invoice>> => {
    const response: AxiosResponse<Invoice> = await api.get<Invoice>(
      `invoices/${key}`
    );

    return response;
  },

  findAll: async (
    companyRecipientId: string
  ): Promise<AxiosResponse<Invoice[]>> => {
    const response: AxiosResponse<Invoice[]> = await api.get<Invoice[]>(
      "invoices",
      {
        params: {
          companyRecipientId,
        },
      }
    );

    return response;
  },

  delete: async (key: string): Promise<AxiosResponse<Invoice>> => {
    const response: AxiosResponse<Invoice> = await api.delete<Invoice>(
      `invoices/${key}`
    );

    return response;
  },
};

export default invoicesService;
