import { persistReducer } from "redux-persist";

import rootReducer from "../reducers/";

import { AnyAction } from "redux";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";

const persistedReducer = persistReducer<
  ReturnType<typeof rootReducer>,
  AnyAction
>(
  {
    key: String(process.env.NEXT_PUBLIC_REDUX_STORAGE_KEY),
    storage,
    whitelist: ["session"],
    blacklist: ["loader"],
    stateReconciler: autoMergeLevel2,
  },
  rootReducer
);

export default persistedReducer;
