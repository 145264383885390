import React from "react";

import { ChakraProvider } from "@chakra-ui/react";
import ReactDOM from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";

import "filepond/dist/filepond.min.css";
import "sweetalert2/src/sweetalert2.scss";

import { theme } from "./config/theme";
import store, { persistor } from "./core/stores";

import Loader from "./layout/components/Loader";
import Pages from "./pages";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ChakraProvider theme={theme}>
          <BrowserRouter>
            <Pages />
          </BrowserRouter>
          <Loader />
        </ChakraProvider>
      </PersistGate>
    </ReduxProvider>
  </React.StrictMode>
);
