import {
  Badge,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import moment from "moment";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Company } from "../../../../core/models/Company";
import {
  NewUserCompanyRelationshipRequest,
  UserCompany,
} from "../../../../core/models/User";
import companiesService from "../../../../core/services/companies";
import usersService from "../../../../core/services/user";
import { setLoader } from "../../../../core/stores/reducers/Loader";
import Alert from "../../../../core/utils/Alert";
import { formatCNPJ } from "../../../../core/utils/FormatCNPJ";
import { LocalComponentsProps, UserResourcesListTableProps } from "../types";

function ButtonCreateOrRevokeAccess({
  action,
  onClick,
}: Omit<LocalComponentsProps, "type"> & {
  action: "add" | "remove";
  onClick: MouseEventHandler<any> | undefined;
}) {
  const buttonColor = action === "add" ? "blue" : "red";

  return (
    <Button
      bg={`${buttonColor}.400`}
      color={"white"}
      _hover={{
        bg: `${buttonColor}.500`,
      }}
      onClick={onClick}
    >
      {action === "add" ? "Conceder acesso" : "Revogar acesso"}
    </Button>
  );
}

export default function CompaniesTable({
  user,
  setCurrentModalProps,
}: UserResourcesListTableProps) {
  const [availableCompanies, setAvailableCompanies] = useState<Company[]>(
    [] as Company[]
  );

  const [userCompanies, setUserCompanies] = useState<UserCompany[]>(
    [] as UserCompany[]
  );

  const filteredCompanies = availableCompanies.filter(
    (availableCompany) =>
      !userCompanies.some(
        (userCompany) =>
          userCompany.company.document === availableCompany.document
      )
  );

  const dispatch = useDispatch();

  const getAvailableAndCurrentCompanies = useCallback(() => {
    if (user) {
      const { _id: userId } = user;

      dispatch(
        setLoader({
          isLoading: true,
        })
      );

      Promise.all([
        companiesService.findAll(),
        usersService.findAllCompanies(userId),
      ])
        .then(([response1, response2]) => {
          const { data: availableCompaniesData } = response1;
          const { data: userCompaniesData } = response2;

          setAvailableCompanies(availableCompaniesData);
          setUserCompanies(userCompaniesData);
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 403) {
            Alert.danger({
              message:
                "Você não tem permissões para executar esta ação. Contate o administrador do sistema!",
            });
          } else {
            Alert.danger({
              message: "Ocorreu um erro desconhecido",
            });
          }
          setCurrentModalProps(null);
        })
        .finally(() => {
          dispatch(
            setLoader({
              isLoading: false,
            })
          );
        });
    }
  }, [dispatch, setCurrentModalProps, user]);

  const createUserCopanyRelationship = useCallback(
    (companyId: string) => {
      if (user && companyId) {
        const { _id: userId } = user;

        dispatch(
          setLoader({
            isLoading: true,
          })
        );

        const data: NewUserCompanyRelationshipRequest = {
          company: companyId,
          user: userId,
        };

        usersService
          .createCompanyRelationship(data)
          .then(() => {
            getAvailableAndCurrentCompanies();
          })
          .catch(() => {
            Alert.danger({
              message: "Ocorreu um erro ao tentar adicionar a permissão",
            });
          })
          .finally(() => {
            dispatch(
              setLoader({
                isLoading: false,
              })
            );
          });
      }
    },
    [dispatch, getAvailableAndCurrentCompanies, user]
  );

  const removeUserCopanyRelationship = useCallback(
    (userCompanyRelationShipId: string) => {
      if (user && userCompanyRelationShipId) {
        usersService
          .removeCompanyRelationship(userCompanyRelationShipId)
          .then(() => {
            getAvailableAndCurrentCompanies();
          })
          .catch(() => {
            Alert.danger({
              message: "Ocorreu um erro ao tentar revogar o acesso",
            });
          })
          .finally(() => {
            dispatch(
              setLoader({
                isLoading: false,
              })
            );
          });
      }
    },
    [dispatch, getAvailableAndCurrentCompanies, user]
  );

  useEffect(() => {
    getAvailableAndCurrentCompanies();
  }, [getAvailableAndCurrentCompanies]);

  return (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>Empresa:</Th>
            <Th>CNPJ:</Th>
            <Th>Status:</Th>
            <Th>Data adição:</Th>
            <Th>Ação:</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userCompanies.map((userCompany) => {
            return (
              <Tr>
                <Td>{userCompany.company.name}</Td>
                <Td>{formatCNPJ(userCompany.company.document)}</Td>
                <Td>
                  <Badge colorScheme="green" variant={"solid"}>
                    Acesso permitido
                  </Badge>
                </Td>
                <Td>
                  {moment(userCompany.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                </Td>
                <Td>
                  <ButtonCreateOrRevokeAccess
                    action={"remove"}
                    onClick={() => {
                      removeUserCopanyRelationship(userCompany._id);
                    }}
                  />
                </Td>
              </Tr>
            );
          })}
          {filteredCompanies.map((company) => {
            return (
              <Tr>
                <Td>{company.name}</Td>
                <Td>{formatCNPJ(company.document)}</Td>
                <Td>
                  <Badge colorScheme="red" variant={"solid"}>
                    Sem acesso
                  </Badge>
                </Td>
                <Td>-</Td>
                <Td>
                  <ButtonCreateOrRevokeAccess
                    action={"add"}
                    onClick={() => {
                      createUserCopanyRelationship(company._id);
                    }}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
