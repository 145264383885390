import { AxiosResponse } from "axios";
import { ActualFileObject } from "filepond";
import FormDataFront from "form-data";
import { UploadResponse } from "../../models/Upload";
import api from "../api";

const uploadsService = {
  sendNewFile: async (
    file: ActualFileObject
  ): Promise<AxiosResponse<UploadResponse>> => {
    const data = new FormDataFront();
    data.append("file", file);

    const response: AxiosResponse<UploadResponse> = await api.post(
      "uploads",
      data,
      {
        headers: data.getHeaders
          ? data.getHeaders()
          : { "Content-Type": "multipart/form-data" },
      }
    );

    return response;
  },
};

export default uploadsService;
