import { AxiosResponse } from "axios";
import { Company, NewCompanyRequest } from "../../models/Company";
import { Stock } from "../../models/Stock";
import api from "../api";

const companiesService = {
  create: async (data: NewCompanyRequest): Promise<AxiosResponse<Company>> => {
    const response: AxiosResponse<Company> = await api.post<Company>(
      "companies",
      data
    );

    return response;
  },

  findAll: async (): Promise<AxiosResponse<Company[]>> => {
    const response: AxiosResponse<Company[]> = await api.get<Company[]>(
      "companies"
    );

    return response;
  },

  getFullStock: async (id: string): Promise<AxiosResponse<Stock[]>> => {
    const response: AxiosResponse<Stock[]> = await api.get<Stock[]>(
      `companies/${id}/stock`
    );

    return response;
  },
};

export default companiesService;
