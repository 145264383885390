import { AxiosResponse } from "axios";
import {
  NewStockRequest,
  Stock,
  StockMovimentations,
} from "../../models/Stock";
import api from "../api";

const stocksService = {
  getStockMovimentations: async (
    stockId: string
  ): Promise<AxiosResponse<StockMovimentations[]>> => {
    const response: AxiosResponse<StockMovimentations[]> = await api.get<
      StockMovimentations[]
    >(`stocks/${stockId}/movimentations`);

    return response;
  },
  create: async (data: NewStockRequest): Promise<AxiosResponse<Stock>> => {
    const response: AxiosResponse<Stock> = await api.post<Stock>(
      "stocks",
      data
    );

    return response;
  },
};

export default stocksService;
