import { NotAllowedIcon } from "@chakra-ui/icons";
import {
  Badge,
  Button,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from "@chakra-ui/react";
import { AxiosError } from "axios";
import moment from "moment";
import { MouseEventHandler, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { App } from "../../../../core/models/App";
import {
  NewPermissionRequest,
  Permission,
} from "../../../../core/models/Permission";
import appsService from "../../../../core/services/app";
import permissionsService from "../../../../core/services/permissions";
import { setLoader } from "../../../../core/stores/reducers/Loader";
import Alert from "../../../../core/utils/Alert";
import isAppAdmin from "../../../../core/utils/isAppAdmin";
import { LocalComponentsProps, UserResourcesListTableProps } from "../types";

function PermissionType({ type }: LocalComponentsProps) {
  return (
    <>
      {isAppAdmin(type) ? (
        <Badge variant="outline" colorScheme="purple">
          Administrador
        </Badge>
      ) : (
        <Badge variant="outline">Todos os usuários</Badge>
      )}
    </>
  );
}

function ButtonAddOrRemove({
  type,
  action,
  onClick,
}: LocalComponentsProps & {
  action: "add" | "remove";
  onClick: MouseEventHandler<any> | undefined;
}) {
  const buttonColor = action === "add" ? "blue" : "red";

  return isAppAdmin(type) ? (
    <Tooltip
      label={`Não é possível ${
        action === "add" ? "adicionar" : "remover"
      } permissões de nível administrativo. Para isso, contate o administrador do sistema`}
      fontSize="md"
    >
      <NotAllowedIcon />
    </Tooltip>
  ) : (
    <Button
      bg={`${buttonColor}.400`}
      color={"white"}
      _hover={{
        bg: `${buttonColor}.500`,
      }}
      onClick={onClick}
    >
      {action === "add" ? "Adicionar" : "Remover"}
    </Button>
  );
}

export default function PermissionsTable({
  user,
  setCurrentModalProps,
}: UserResourcesListTableProps) {
  const [apps, setApps] = useState<App[]>([] as App[]);

  const [permissions, setPermissions] = useState<Permission[]>(
    [] as Permission[]
  );

  const dispatch = useDispatch();

  const filteredApps = apps.filter(
    (app) => !permissions.some((permission) => permission.app.slug === app.slug)
  );

  const getPermissionsAndApps = useCallback(async () => {
    if (user) {
      dispatch(
        setLoader({
          isLoading: true,
        })
      );

      Promise.all([permissionsService.findAll(user._id), appsService.findAll()])
        .then(([response1, response2]) => {
          const { data: permissionsData } = response1;
          const { data: appsData } = response2;

          setPermissions(permissionsData);
          setApps(appsData);
        })
        .catch((error: AxiosError) => {
          if (error.response?.status === 403) {
            Alert.danger({
              message:
                "Você não tem permissões para executar esta ação. Contate o administrador do sistema!",
            });
          } else {
            Alert.danger({
              message: "Ocorreu um erro desconhecido",
            });
          }
          setCurrentModalProps(null);
        })
        .finally(() => {
          dispatch(
            setLoader({
              isLoading: false,
            })
          );
        });
    }
  }, [user, dispatch, setCurrentModalProps]);

  const addPermission = useCallback(
    (appId: string) => {
      if (user) {
        dispatch(
          setLoader({
            isLoading: true,
          })
        );

        const data: NewPermissionRequest = {
          permissions: [
            {
              app: appId,
              user: user._id,
            },
          ],
        };
        permissionsService
          .create(data)
          .then(() => {
            getPermissionsAndApps();
          })
          .catch(() => {
            Alert.danger({
              message: "Ocorreu um erro ao tentar adicionar a permissão",
            });
          })
          .finally(() => {
            dispatch(
              setLoader({
                isLoading: false,
              })
            );
          });
      }
    },
    [user, dispatch, getPermissionsAndApps]
  );

  const removePermission = (permissionId: string) => {
    permissionsService
      .delete(permissionId)
      .then(() => {
        getPermissionsAndApps();
      })
      .catch(() => {
        Alert.danger({
          message: "Ocorreu um erro ao tentar remover a permissão",
        });
      })
      .finally(() => {
        dispatch(
          setLoader({
            isLoading: false,
          })
        );
      });
  };

  useEffect(() => {
    getPermissionsAndApps();
  }, [getPermissionsAndApps]);

  return (
    <TableContainer>
      <Table variant="striped">
        <Thead>
          <Tr>
            <Th>App:</Th>
            <Th>Slug:</Th>
            <Th>Tipo:</Th>
            <Th>Status:</Th>
            <Th>Data adição:</Th>
            <Th>Ação:</Th>
          </Tr>
        </Thead>
        <Tbody>
          {permissions.map((permission) => {
            return (
              <Tr key={permission._id}>
                <Td>{permission.app.name}</Td>
                <Td>
                  <Badge variant="outline" colorScheme="blue">
                    {permission.app.slug}
                  </Badge>
                </Td>
                <Td>
                  <PermissionType type={permission.app.type} />
                </Td>
                <Td>
                  <Badge colorScheme="green" variant={"solid"}>
                    Ativo
                  </Badge>
                </Td>
                <Td>
                  {moment(permission.createdAt).format("DD/MM/YYYY HH:mm:ss")}
                </Td>
                <Td>
                  <ButtonAddOrRemove
                    type={permission.app.type}
                    action="remove"
                    onClick={() => {
                      removePermission(permission._id);
                    }}
                  />
                </Td>
              </Tr>
            );
          })}
          {filteredApps.map((app) => {
            return (
              <Tr key={app._id}>
                <Td>{app.name}</Td>
                <Td>
                  <Badge variant="outline" colorScheme="blue">
                    {app.slug}
                  </Badge>
                </Td>
                <Td>
                  <PermissionType type={app.type} />
                </Td>
                <Td>
                  <Badge colorScheme="red" variant={"solid"}>
                    Inativo
                  </Badge>
                </Td>
                <Td>-</Td>
                <Td>
                  <ButtonAddOrRemove
                    type={app.type}
                    action="add"
                    onClick={() => {
                      addPermission(app._id);
                    }}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
