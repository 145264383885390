import * as yup from "yup";

const newUserSchema = yup.object({
  name: yup.string().required("Informe o nome do produto"),
  document: yup.string().required("Informe o documento do usuário"),
  email: yup.string().required("Informe o email do usuário"),
  password: yup.string().required("Informe uma senha para o usuário"),
});

export default newUserSchema;
