"use client";

import {
  Box,
  Button,
  Container,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { NewUserRequest } from "../../../core/models/User";
import usersService from "../../../core/services/user";
import { setLoader } from "../../../core/stores/reducers/Loader";
import Alert from "../../../core/utils/Alert";
import newUserSchema from "../../../core/validations/newUser";

export default function NewUser() {
  const dispatch = useDispatch();

  const { control, handleSubmit, formState, reset } = useForm<NewUserRequest>({
    resolver: yupResolver(newUserSchema),
    mode: "all",
  });

  const { isValid } = formState;

  const create = handleSubmit((data: NewUserRequest) => {
    dispatch(
      setLoader({
        isLoading: true,
      })
    );
    usersService
      .create(data)
      .then((response) => {
        Alert.success({
          message: "Usuário criado com sucesso",
        });
        reset();
      })
      .catch((error) => {
        Alert.danger({
          message: "Ocorreu um erro ao tentar cadastrar o usuário",
        });
      })
      .finally(() => {
        dispatch(
          setLoader({
            isLoading: false,
          })
        );
      });
  });

  return (
    <>
      <Container maxW={"3xl"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Crie um
            <br />
            <Text as={"span"} color={"primary.400"}>
              novo usuário
            </Text>
          </Heading>

          <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
            <Stack spacing={4}>
              <FormControl id="name">
                <Controller
                  control={control}
                  name="name"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      type="name"
                      placeholder="Nome do usuário"
                      value={value || ""}
                      onChange={onChange}
                      onBlur={onBlur}
                      autoComplete="off"
                    />
                  )}
                />
              </FormControl>
              <FormControl id="document">
                <Controller
                  control={control}
                  name="document"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      type="text"
                      placeholder="CPF do usuário"
                      value={value || ""}
                      onChange={onChange}
                      onBlur={onBlur}
                      maxLength={11}
                      autoComplete="off"
                    />
                  )}
                />
              </FormControl>
              <FormControl id="email">
                <Controller
                  control={control}
                  name="email"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      type="email"
                      placeholder="Email do usuário"
                      value={value || ""}
                      onChange={onChange}
                      onBlur={onBlur}
                      autoComplete="off"
                    />
                  )}
                />
              </FormControl>
              <FormControl id="password">
                <Controller
                  control={control}
                  name="password"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      type="password"
                      placeholder="Senha do usuário"
                      value={value || ""}
                      onChange={onChange}
                      onBlur={onBlur}
                      autoComplete="off"
                    />
                  )}
                />
              </FormControl>
              <Stack spacing={10}>
                <Button
                  bg={"primary.400"}
                  color={"white"}
                  _hover={{
                    bg: "primary.500",
                  }}
                  isDisabled={!isValid}
                  onClick={create}
                >
                  Salvar
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </>
  );
}
