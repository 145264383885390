import { AxiosResponse } from "axios";
import { DeleteResourceResponse } from "../../models/GenericRequest";
import { NewPermissionRequest, Permission } from "../../models/Permission";
import api from "../api";

const permissionsService = {
  check: async (name: string): Promise<AxiosResponse<Permission>> => {
    const response: AxiosResponse<Permission> = await api.get<Permission>(
      `permissions/${name}/auth`
    );

    return response;
  },

  findAll: async (userId: string): Promise<AxiosResponse<Permission[]>> => {
    const response: AxiosResponse<Permission[]> = await api.get<Permission[]>(
      `permissions/user/${userId}`
    );

    return response;
  },

  create: async (
    data: NewPermissionRequest
  ): Promise<AxiosResponse<Permission[]>> => {
    const response: AxiosResponse<Permission[]> = await api.post<Permission[]>(
      "permissions",
      data
    );

    return response;
  },

  delete: async (
    permissionId: string
  ): Promise<AxiosResponse<DeleteResourceResponse>> => {
    const response: AxiosResponse<DeleteResourceResponse> =
      await api.delete<DeleteResourceResponse>(`permissions/${permissionId}`);

    return response;
  },
};

export default permissionsService;
