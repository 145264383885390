import {
  Badge,
  Box,
  Button,
  Center,
  Container,
  Heading,
  Image,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Stock, StockMovimentations } from "../../../core/models/Stock";
import { UserCompany } from "../../../core/models/User";
import companiesService from "../../../core/services/companies";
import stocksService from "../../../core/services/stocks";
import usersService from "../../../core/services/user";
import { setLoader } from "../../../core/stores/reducers/Loader";
import { selectSession } from "../../../core/stores/reducers/Session";
import Alert from "../../../core/utils/Alert";
import Select from "../../../layout/components/Select";

export default function StockProducts() {
  const [userCompanies, setUserCompanies] = useState<UserCompany[]>(
    [] as UserCompany[]
  );
  const [companyId, setCompanyId] = useState<string | null>(null);
  const [stocks, setStocks] = useState<Stock[]>([] as Stock[]);
  const [currentStockViewing, setCurrentStockViewing] = useState<Stock | null>(
    null
  );
  const [stockMovimentations, setStockMovimentations] = useState<
    StockMovimentations[]
  >([] as StockMovimentations[]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const { user } = useSelector(selectSession);

  const options = userCompanies.map(({ company: { name, _id } }) => ({
    label: name,
    value: _id,
  }));

  const findFullStock = useCallback(() => {
    if (companyId) {
      dispatch(
        setLoader({
          isLoading: true,
        })
      );

      companiesService
        .getFullStock(companyId)
        .then(({ data }) => {
          setStocks(data);
        })
        .catch((error) => {
          Alert.danger({
            message: "Ocorreu um erro ao tentar buscar o estoque da empresa",
          });
        })
        .finally(() => {
          dispatch(
            setLoader({
              isLoading: false,
            })
          );
        });
    } else {
      setStocks([]);
    }
  }, [companyId, dispatch]);

  const findAllCompaniesByUser = useCallback(() => {
    dispatch(
      setLoader({
        isLoading: true,
      })
    );

    if (user) {
      usersService
        .findAllCompanies(user._id)
        .then((response) => {
          const { data } = response;
          setUserCompanies(data);
        })
        .catch(() => {
          Alert.danger({
            message: "Ocorreu um erro ao tentar buscar os dados das empresas",
          });
        })
        .finally(() => {
          dispatch(
            setLoader({
              isLoading: false,
            })
          );
        });
    }
  }, [user, dispatch]);

  const getMovimentations = useCallback(() => {
    if (currentStockViewing) {
      dispatch(
        setLoader({
          isLoading: true,
        })
      );

      stocksService
        .getStockMovimentations(currentStockViewing._id)
        .then(({ data }) => {
          setStockMovimentations(data);
        })
        .catch(() => {
          Alert.danger({
            message: "Ocorreu um erro ao tentar realizar o login",
          });
        })
        .finally(() => {
          dispatch(
            setLoader({
              isLoading: false,
            })
          );
        });
    }
  }, [currentStockViewing, dispatch]);

  const handleOpenOrCloseModal = useCallback(() => {
    if (currentStockViewing) {
      onOpen();
    } else {
      onClose();
    }
  }, [currentStockViewing, onClose, onOpen]);

  useEffect(() => {
    findAllCompaniesByUser();
  }, [findAllCompaniesByUser]);

  useEffect(() => {
    findFullStock();
  }, [findFullStock]);

  useEffect(() => {
    handleOpenOrCloseModal();
  }, [handleOpenOrCloseModal]);

  useEffect(() => {
    getMovimentations();
  }, [getMovimentations]);

  return (
    <>
      {currentStockViewing && (
        <Modal
          onClose={() => {
            setCurrentStockViewing(null);
          }}
          size={"full"}
          isOpen={isOpen}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Box display={"flex"} flex={1} flexDir={"row"}>
                <Text fontWeight={400}>Produto:</Text>
                <a href={`/products/detail/${currentStockViewing.product._id}`}>
                  <Text fontWeight={700} ml={1} textDecoration="underline">
                    {currentStockViewing.product.name}
                  </Text>
                </a>
              </Box>

              <Box display={"flex"} flex={1} flexDir={"row"}>
                <Text fontWeight={400}>Cód. barras:</Text>
                <Text fontWeight={700} ml={1}>
                  {currentStockViewing.product.barcode ?? "-"}
                </Text>
              </Box>

              <Box display={"flex"} flex={1} flexDir={"row"}>
                <Text fontWeight={400}>Quantidade atual:</Text>
                <Text fontWeight={700} ml={1}>
                  {currentStockViewing.quantity}
                </Text>
              </Box>

              <Box display={"flex"} flex={1} flexDir={"row"}>
                <Text fontWeight={400}>Data cadastro:</Text>
                <Text fontWeight={700} ml={1}>
                  {moment(currentStockViewing.product.createdAt).format(
                    "DD/MM/YYYY HH:mm:ss"
                  )}
                </Text>
              </Box>

              <Box display={"flex"} flex={1} flexDir={"row"}>
                <Text fontWeight={400}>Foto:</Text>
                <Image
                  src={
                    currentStockViewing.product.pictureUrl ??
                    require("../../../core/assets/noPicture.jpeg")
                  }
                  alt={currentStockViewing.product.name}
                  boxSize="64px"
                  objectFit="cover"
                  borderRadius={
                    currentStockViewing.product.pictureUrl ? "md" : "full"
                  }
                  ml={1}
                />
              </Box>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {stockMovimentations.length === 0 ? (
                <Center w="100%" flexDirection="column">
                  <Box
                    borderRadius="lg"
                    borderWidth="1px"
                    p="4"
                    boxShadow="md"
                    bgColor="white"
                    textAlign="center"
                  >
                    <Text fontSize="xl" fontWeight="medium" mb="4">
                      Sem registros de movimentação
                    </Text>
                    <Text color="gray.500">
                      O produto selecionado não apresenta registros de
                      <br />
                      movimentação, o que pode indicar que seja um item novo ou
                      <br />
                      que as movimentações anteriores tenham sido excluídas.
                    </Text>
                  </Box>
                </Center>
              ) : (
                <TableContainer>
                  <Table variant="striped">
                    <Thead>
                      <Tr>
                        <Th>Tipo:</Th>
                        <Th>NF:</Th>
                        <Th>Quantidade movimentada:</Th>
                        <Th>Usuário:</Th>
                        <Th>Data:</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {stockMovimentations.map((stockMovimentation) => {
                        const isStockEntry = stockMovimentation.quantity >= 0;

                        return (
                          <Tr key={stockMovimentation._id}>
                            <Td>
                              <Badge
                                variant="outline"
                                colorScheme={isStockEntry ? "green" : "red"}
                              >
                                {isStockEntry ? "Entrada" : "Saída"}
                              </Badge>
                            </Td>
                            <Td>
                              {stockMovimentation.invoice ? (
                                <Link
                                  target="_blank"
                                  href={`/invoice/search?key=${stockMovimentation.invoice.key}`}
                                >
                                  {stockMovimentation.invoice.key}
                                </Link>
                              ) : (
                                "Movimentação manual"
                              )}
                            </Td>
                            <Td>{stockMovimentation.quantity}</Td>
                            <Td>{stockMovimentation.user.name}</Td>
                            <Td>
                              {moment(stockMovimentation.createdAt).format(
                                "DD/MM/YYYY HH:mm:ss"
                              )}
                            </Td>
                          </Tr>
                        );
                      })}
                    </Tbody>
                  </Table>
                </TableContainer>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  setCurrentStockViewing(null);
                }}
              >
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Container
        maxW={"full"}
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
          maxW={"3xl"}
          justifyContent={"center"}
          minWidth={"3xl"}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Extrato de
            <br />
            <Text as={"span"} color={"primary.400"}>
              estoque
            </Text>
          </Heading>

          <Select
            options={options}
            placeholder="Selecione uma empresa"
            onChange={(value) => {
              setCompanyId(value?.value ?? null);
            }}
          />
        </Stack>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
          minWidth={"full"}
        >
          {companyId &&
            (stocks.length === 0 ? (
              <Center w="100%" flexDirection="column">
                <Box
                  borderRadius="lg"
                  borderWidth="1px"
                  p="4"
                  boxShadow="md"
                  bgColor="white"
                  textAlign="center"
                >
                  <Text fontSize="xl" fontWeight="medium" mb="4">
                    Sem registros de movimentação
                  </Text>
                  <Text color="gray.500">
                    A empresa selecionada não possui registros de movimentação
                  </Text>
                </Box>
              </Center>
            ) : (
              <TableContainer>
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>Imagem</Th>
                      <Th>Cód. barras</Th>
                      <Th>Produto:</Th>
                      <Th>Quantidade em estoque:</Th>
                      <Th>Ação:</Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {stocks.map((stock) => {
                      const { product, quantity } = stock;
                      const isNegativeStock = quantity < 0;
                      return (
                        <Tr key={product._id}>
                          <Td>
                            <Image
                              src={
                                product.pictureUrl ??
                                require("../../../core/assets/noPicture.jpeg")
                              }
                              alt={product.name}
                              boxSize="64px"
                              objectFit="cover"
                              borderRadius={product.pictureUrl ? "md" : "full"}
                            />
                          </Td>
                          <Td>{product.barcode ?? "-"}</Td>
                          <Td>{product.name}</Td>
                          <Td>
                            <Badge
                              variant="outline"
                              colorScheme={isNegativeStock ? "red" : "green"}
                            >
                              <Text fontWeight={700} fontSize={16}>
                                {quantity}
                              </Text>
                            </Badge>
                          </Td>
                          <Td>
                            <Button
                              bg={"primary.400"}
                              color={"white"}
                              _hover={{
                                bg: "primary.500",
                              }}
                              onClick={() => {
                                setCurrentStockViewing(stock);
                              }}
                            >
                              Ver movimentações
                            </Button>
                          </Td>
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            ))}
        </Stack>
      </Container>
    </>
  );
}
