import * as yup from "yup";

const userLoginSchema = yup.object({
  email: yup.string().required("Informe o seu email"),
  password: yup.string().required("Informe sua senha"),
  recaptcha: yup
    .string()
    .required("Necessário informar o token recaptcha")
    .min(1, "Necessário informar o token "),
});

export default userLoginSchema;
