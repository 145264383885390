import {
  Box,
  Button,
  Container,
  Heading,
  Image,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Product } from "../../../core/models/Product";
import productsService from "../../../core/services/products";
import { setLoader } from "../../../core/stores/reducers/Loader";
import Alert from "../../../core/utils/Alert";

export interface CurrentModalProps {
  product: Product;
}

export default function ListProducts() {
  const [products, setProducts] = useState<Product[]>([] as Product[]);

  const dispatch = useDispatch();

  const findAll = useCallback(() => {
    dispatch(
      setLoader({
        isLoading: true,
      })
    );

    productsService
      .findAll()
      .then((response) => {
        const { data } = response;
        setProducts(data);
      })
      .catch(() => {
        Alert.danger({
          message: "Ocorreu um erro ao tentar buscar os dados dos produtos",
        });
      })
      .finally(() => {
        dispatch(
          setLoader({
            isLoading: false,
          })
        );
      });
  }, [dispatch]);

  useEffect(() => {
    findAll();
  }, [findAll]);

  return (
    <>
      <Container maxW={"full"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Relatório de
            <br />
            <Text as={"span"} color={"primary.400"}>
              produtos cadastrados
            </Text>
          </Heading>
          <TableContainer>
            <Table variant="striped">
              <TableCaption>
                Esta lista representa todos os produtos cadastrados,
                independente da empresa ou NF
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>Imagem:</Th>
                  <Th>Cód. barras:</Th>
                  <Th>Nome:</Th>
                  <Th>Data do cadastro:</Th>
                  <Th>Ações:</Th>
                </Tr>
              </Thead>
              <Tbody>
                {products.map((product) => {
                  return (
                    <Tr key={product._id}>
                      <Td>
                        <Image
                          src={
                            product.pictureUrl ??
                            require("../../../core/assets/noPicture.jpeg")
                          }
                          alt={product.name}
                          boxSize="64px"
                          objectFit="cover"
                          borderRadius={product.pictureUrl ? "md" : "full"}
                        />
                      </Td>
                      <Td>{product.barcode ?? "-"}</Td>
                      <Td>{product.name}</Td>
                      <Td>
                        {moment(product.createdAt).format(
                          "DD/MM/YYYY HH:mm:ss"
                        )}
                      </Td>
                      <Td>
                        <a
                          href={`/products/detail/${product._id}`}
                          rel="noreferrer"
                        >
                          <Button
                            bg={"blue.400"}
                            color={"white"}
                            _hover={{
                              bg: "blue.500",
                            }}
                          >
                            Ver detalhes
                          </Button>
                        </a>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Container>
    </>
  );
}
