import {
  Box,
  Container,
  Flex,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { Invoice } from "../../../../core/models/Invoice";
import { formatBRL } from "../../../../core/utils/FormatBRL";
import { formatCNPJ } from "../../../../core/utils/FormatCNPJ";

interface Props {
  invoice: Pick<
    Invoice,
    | "key"
    | "natureOfOperation"
    | "model"
    | "serie"
    | "number"
    | "issuer"
    | "recipient"
    | "products"
  >;
}

const InvoiceDetails: React.FC<Props> = ({ invoice }) => {
  return (
    <Container maxW={"full"}>
      <Stack
        as={Box}
        textAlign={"center"}
        spacing={{ base: 2, md: 5 }}
        pb={{ base: 10, md: 18 }}
      >
        <>
          <Flex justifyContent={"flex-start"} pl={"5xl"}>
            <Text as={"span"} color={"primary.400"} fontWeight={"bold"}>
              Dados da Nota fiscal
            </Text>
          </Flex>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Natureza da operação:</Th>
                  <Th>Tipo da operação</Th>
                  <Th>Chave de acesso</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{invoice.natureOfOperation}</Td>
                  <Td>
                    {invoice.natureOfOperation === "0"
                      ? "0 - Entrada"
                      : "1 - Saída"}
                  </Td>
                  <Td>{invoice.key}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Modelo:</Th>
                  <Th>Série</Th>
                  <Th>Número</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{invoice.model}</Td>
                  <Td>{invoice.serie}</Td>
                  <Td>{invoice.number}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <Flex justifyContent={"flex-start"} pl={"5xl"}>
            <Text as={"span"} color={"primary.400"} fontWeight={"bold"}>
              Emitente
            </Text>
          </Flex>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>CNPJ:</Th>
                  <Th>Nome Empresarial</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{formatCNPJ(invoice.issuer.document)}</Td>
                  <Td>{invoice.issuer.name}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Cidade:</Th>
                  <Th>Estado</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{invoice.issuer.city}</Td>
                  <Td>{invoice.issuer.state}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <Flex justifyContent={"flex-start"} pl={"5xl"}>
            <Text as={"span"} color={"primary.400"} fontWeight={"bold"}>
              Destinatário
            </Text>
          </Flex>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>CNPJ:</Th>
                  <Th>Nome Empresarial</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{formatCNPJ(invoice.recipient.document)}</Td>
                  <Td>{invoice.recipient.name}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <TableContainer>
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th>Cidade:</Th>
                  <Th>Estado</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>{invoice.recipient.city}</Td>
                  <Td>{invoice.recipient.state}</Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
          <Flex justifyContent={"flex-start"} pl={"5xl"}>
            <Text as={"span"} color={"primary.400"} fontWeight={"bold"}>
              Produtos
            </Text>
          </Flex>
          <TableContainer>
            <Table variant="striped">
              <Thead>
                <Tr>
                  <Th>Nome:</Th>
                  <Th>Quantidade:</Th>
                  <Th>Unid. Com.:</Th>
                  <Th>Valor Unit.:</Th>
                  <Th>Valor Prod.:</Th>
                </Tr>
              </Thead>
              <Tbody>
                {invoice.products.map((product, key) => {
                  return (
                    <Tr key={key}>
                      <Td>{product.name.trim().toUpperCase()}</Td>
                      <Td>{product.quantity}</Td>
                      <Td>{product.commercializedBusinessUnit}</Td>
                      <Td justifyContent="flex-end">
                        {formatBRL(product.unitValue)}
                      </Td>
                      <Td justifyContent="flex-end">
                        {formatBRL(product.total)}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </>
      </Stack>
    </Container>
  );
};

export default InvoiceDetails;
