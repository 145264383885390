import { AxiosResponse } from "axios";
import { App } from "../../models/App";
import api from "../api";

const appsService = {
  findAll: async (): Promise<AxiosResponse<App[]>> => {
    const response: AxiosResponse<App[]> = await api.get<App[]>("apps");
    return response;
  },
};

export default appsService;
