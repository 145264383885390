import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom"; // Assuming you're using React Router for routing

const PermissionDeniedPage = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
    >
      <Heading size="xl" mb={4}>
        Permission denied
      </Heading>
      <Text fontSize="lg" mb={4}>
        Your permission aren't enough to access this content
      </Text>
      <Button as={Link} to="/home" colorScheme="red">
        Go back to Home
      </Button>
    </Box>
  );
};

export default PermissionDeniedPage;
