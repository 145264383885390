"use client";

import {
  Box,
  Button,
  Container,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { UserUpdatePasswordRequest } from "../../core/models/User";
import usersService from "../../core/services/user";
import { setLoader } from "../../core/stores/reducers/Loader";
import { selectSession } from "../../core/stores/reducers/Session";
import Alert from "../../core/utils/Alert";
import userUpdatePasswordSchema from "../../core/validations/userUpdatePassword";

export default function User() {
  const dispatch = useDispatch();

  const { control, handleSubmit, formState, reset } =
    useForm<UserUpdatePasswordRequest>({
      resolver: yupResolver(userUpdatePasswordSchema),
      mode: "all",
    });

  const { isValid } = formState;

  const { user } = useSelector(selectSession);

  const update = handleSubmit((data: UserUpdatePasswordRequest) => {
    dispatch(
      setLoader({
        isLoading: true,
      })
    );

    usersService
      .updatePassword(data)
      .then(() => {
        Alert.success({
          message: "Senha atualizada com sucesso",
        });
        reset();
      })
      .catch(() => {
        Alert.danger({
          message: "Ocorreu um erro ao tentar atualizar a senha",
        });
      })
      .finally(() => {
        dispatch(
          setLoader({
            isLoading: false,
          })
        );
      });
  });

  return (
    <>
      <Container maxW={"3xl"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            {user?.name.split(" ")[0]}
            <br />
            <Text as={"span"} color={"primary.400"}>
              atualize sua senha
            </Text>
          </Heading>

          <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
            <Stack spacing={4}>
              <FormControl id="currentPassword">
                <Controller
                  control={control}
                  name="currentPassword"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      type="password"
                      placeholder="Informe sua senha atual"
                      value={value || ""}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </FormControl>
              <FormControl id="newPassword">
                <Controller
                  control={control}
                  name="newPassword"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      type="password"
                      placeholder="Informe a nova senha"
                      value={value || ""}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </FormControl>
              <FormControl id="newPasswordConfirmation">
                <Controller
                  control={control}
                  name="newPasswordConfirmation"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Input
                      type="password"
                      placeholder="Confirme a nova senha"
                      value={value || ""}
                      onChange={onChange}
                      onBlur={onBlur}
                    />
                  )}
                />
              </FormControl>
              <Stack spacing={10}>
                <Button
                  bg={"primary.400"}
                  color={"white"}
                  _hover={{
                    bg: "primary.500",
                  }}
                  isDisabled={!isValid}
                  onClick={update}
                >
                  Salvar
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </>
  );
}
