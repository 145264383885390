import Swal, { SweetAlertOptions } from "sweetalert2";

interface AlertProps {
  title?: string;
  message: string;
  duration?: number;
  autoClose?: boolean;
}

let timerInterval: string | number | NodeJS.Timer | undefined;

export const timer = 1200;

const timeOptions: SweetAlertOptions = {
  timer,
  timerProgressBar: true,
  didOpen: () => {
    Swal.showLoading();
    const b = Swal.getHtmlContainer()?.querySelector("b");
    timerInterval = setInterval(() => {
      if (b) b.textContent = String(Swal.getTimerLeft());
    }, 100);
  },
  willClose: () => {
    clearInterval(timerInterval);
  },
};

const allowOutsideClick = false;

const Alert = {
  success: (options: AlertProps) => {
    Swal.fire({
      title: options.title ?? "Sucesso!",
      icon: "success",
      confirmButtonText: "Ok",
      html: options.message,
      allowOutsideClick,
      ...(options.autoClose ? timeOptions : {}),
    });
  },
  warning: (options: AlertProps) => {
    Swal.fire({
      title: options.title ?? "Atenção!",
      icon: "warning",
      confirmButtonText: "Ok, entendi",
      html: options.message,
      allowOutsideClick,
      ...(options.autoClose ? timeOptions : {}),
    });
  },

  danger: (options: AlertProps) => {
    Swal.fire({
      title: options.title ?? "Erro!",
      icon: "error",
      confirmButtonText: "Ok, entendi",
      html: options.message,
      allowOutsideClick,
      ...(options.autoClose ? timeOptions : {}),
    });
  },

  info: (options: AlertProps) => {
    Swal.fire({
      title: options.title ?? "Atenção!",
      icon: "info",
      confirmButtonText: "Ok, entendi",
      html: options.message,
      allowOutsideClick,
      ...(options.autoClose ? timeOptions : {}),
    });
  },
};

export default Alert;
