import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import permissionsService from "../core/services/permissions";
import { setLoader } from "../core/stores/reducers/Loader";
import { selectSession } from "../core/stores/reducers/Session";
import Header from "../layout/components/Header";
import ListCompanies from "./Company/List";
import NewCompany from "./Company/New";
import Home from "./Home";
import ImportInvoice from "./Invoice/Import";
import InvoiceReports from "./Invoice/Report";
import InvoiceSearch from "./Invoice/Search";
import Login from "./Login";
import PermissionDeniedPage from "./PermissionDenied";
import ProductDetail from "./Products/Detail";
import ListProducts from "./Products/List";
import NewProduct from "./Products/New";
import StockIntake from "./Stock/Intake";
import StockProducts from "./Stock/Products";
import User from "./User";
import ListUsers from "./User/List";
import NewUser from "./User/New";

const ProtectedRoute = ({
  children,
  slug,
}: {
  children: JSX.Element;
  slug: string;
}): JSX.Element => {
  const [isCheckingForPermission, setIsCheckingForPermission] =
    useState<boolean>(true);
  const [hasPermission, setHasPermission] = useState<boolean>(false);
  const { token } = useSelector(selectSession);

  const dispatch = useDispatch();

  const checkPermission = useCallback(() => {
    dispatch(
      setLoader({
        isLoading: true,
      })
    );

    permissionsService
      .check(slug)
      .then(() => {
        setHasPermission(true);
      })
      .catch((error) => {
        setHasPermission(false);
      })
      .finally(() => {
        setIsCheckingForPermission(false);

        dispatch(
          setLoader({
            isLoading: false,
          })
        );
      });
  }, [dispatch, slug]);

  useEffect(() => {
    checkPermission();
  }, [checkPermission]);

  if (!token) return <Navigate to="/" replace />;

  return (
    <>
      <Header />
      {isCheckingForPermission ? (
        <></>
      ) : hasPermission ? (
        children
      ) : (
        <PermissionDeniedPage />
      )}
    </>
  );
};

function Pages() {
  return (
    <>
      <Routes>
        <Route index element={<Login />} />
        <Route
          path="home"
          element={
            <ProtectedRoute slug="home">
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="user"
          element={
            <ProtectedRoute slug="user">
              <User />
            </ProtectedRoute>
          }
        />
        <Route
          path="user/list"
          element={
            <ProtectedRoute slug="user.list">
              <ListUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path="user/new"
          element={
            <ProtectedRoute slug="user.new">
              <NewUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="company/new"
          element={
            <ProtectedRoute slug="company.new">
              <NewCompany />
            </ProtectedRoute>
          }
        />
        <Route
          path="company/list"
          element={
            <ProtectedRoute slug="company.list">
              <ListCompanies />
            </ProtectedRoute>
          }
        />
        <Route
          path="invoice/import"
          element={
            <ProtectedRoute slug="invoice.import">
              <ImportInvoice />
            </ProtectedRoute>
          }
        />
        <Route
          path="invoice/report"
          element={
            <ProtectedRoute slug="invoice.report">
              <InvoiceReports />
            </ProtectedRoute>
          }
        />
        <Route
          path="invoice/search"
          element={
            <ProtectedRoute slug="invoice.search">
              <InvoiceSearch />
            </ProtectedRoute>
          }
        />
        <Route
          path="stock/products"
          element={
            <ProtectedRoute slug="stock.products">
              <StockProducts />
            </ProtectedRoute>
          }
        />
        <Route
          path="stock/intake"
          element={
            <ProtectedRoute slug="stock.intake">
              <StockIntake type="INTAKE" />
            </ProtectedRoute>
          }
        />
        <Route
          path="stock/output"
          element={
            <ProtectedRoute slug="stock.output">
              <StockIntake type="OUTPUT" />
            </ProtectedRoute>
          }
        />
        <Route
          path="products/new"
          element={
            <ProtectedRoute slug="products.new">
              <NewProduct />
            </ProtectedRoute>
          }
        />
        <Route
          path="products/list"
          element={
            <ProtectedRoute slug="products.list">
              <ListProducts />
            </ProtectedRoute>
          }
        />
        <Route
          path="products/detail/:productId"
          element={
            <ProtectedRoute slug="products.list">
              <ProductDetail />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}

export default Pages;
