import * as yup from "yup";

const searchNFSchema = yup.object({
  key: yup
    .string()
    .required("Informe a chave")
    .min(44, "É necesário informar uma chave de 44 caracteres")
    .max(44, "É necesário informar uma chave de no máximo 44 caracteres"),
});

export default searchNFSchema;
