import * as yup from "yup";

const newProductSchema = yup.object({
  name: yup.string().required("Informe o nome do produto"),
  pictureUrl: yup
    .string()
    .url("O URL da foto do produto é inválido")
    .required("Informe a foto do produto"),

  barcode: yup.string().required("Informe o código de barras do produto"),
});

export default newProductSchema;
