import * as yup from "yup";

const userUpdatePasswordSchema = yup.object({
  currentPassword: yup.string().required("Informe a senha atual"),
  newPassword: yup.string().required("Informe a nova senha"),
  newPasswordConfirmation: yup
    .string()
    .required("Informe a confirmação da nova senha")
    .oneOf([yup.ref("newPassword"), null], "As senhas não conferem"),
});

export default userUpdatePasswordSchema;
