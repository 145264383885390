import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../stores/index";

export interface LoaderState {
  isLoading: boolean;
  text?: string;
}

const initialState: LoaderState = {
  isLoading: false,
};

const slice = createSlice({
  name: "loader",
  initialState,
  reducers: {
    setLoader: (state, action: PayloadAction<LoaderState>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setLoader } = slice.actions;

export const selectLoader = (state: RootState) => state.loader;

export default slice.reducer;
