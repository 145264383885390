import {
  Badge,
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { Invoice, SearchInvoiceRequest } from "../../../core/models/Invoice";
import invoicesService from "../../../core/services/invoices";
import { setLoader } from "../../../core/stores/reducers/Loader";
import Alert from "../../../core/utils/Alert";
import useQuery from "../../../core/utils/useQuery";
import searchNFSchema from "../../../core/validations/searchNF";
import InvoiceDetails from "../../../layout/components/Invoice/Details";

export default function InvoiceSearch() {
  const [invoice, setInvoice] = useState<Invoice | null>(null);

  const dispatch = useDispatch();

  const query = useQuery();
  const keyValue = query.get("key");

  const { control, handleSubmit, formState } = useForm<SearchInvoiceRequest>({
    resolver: yupResolver(searchNFSchema),
    mode: "all",
    defaultValues: {
      key: keyValue ?? "",
    },
  });

  const { errors, isValid } = formState;

  const search = handleSubmit((data: SearchInvoiceRequest) => {
    dispatch(
      setLoader({
        isLoading: true,
      })
    );

    setInvoice(null);

    invoicesService
      .findByKey(data.key)
      .then(({ data }) => {
        setInvoice(data);
      })
      .catch(() => {
        Alert.danger({
          message: "Ocorreu um erro ao tentar buscar os dados da NF",
        });
      })
      .finally(() => {
        dispatch(
          setLoader({
            isLoading: false,
          })
        );
      });
  });

  const handleCancelInvoice = useCallback(async () => {
    if (invoice) {
      const dialogResponse = await Swal.fire({
        title: "Você tem certeza que deseja excluir esta NF?",
        text: "Não será possível reverter isso a menos que você importe novamente!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, eu quero deletar!",
        cancelButtonText: "Não, eu quero desistir!",
      });

      if (dialogResponse.isConfirmed) {
        dispatch(
          setLoader({
            isLoading: true,
            text: "Excluindo nota fiscal...",
          })
        );

        invoicesService
          .delete(invoice.key)
          .then(() => {
            Alert.success({
              message: "Nota fiscal excluída com sucesso",
            });
            setInvoice(null);
          })
          .catch(() => {
            Alert.danger({
              message: "Falha ao tentar excluir NF",
            });
          })
          .finally(() => {
            dispatch(
              setLoader({
                isLoading: false,
              })
            );
          });
      }
    }
  }, [dispatch, invoice]);

  useEffect(() => {
    if (keyValue && keyValue.length === 44) {
      search();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyValue]);

  return (
    <>
      <Container maxW={"full"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Pesquisar
            <br />
            <Text as={"span"} color={"primary.400"}>
              nota fiscal
            </Text>
          </Heading>

          <Box
            rounded={"lg"}
            bg={"white"}
            boxShadow={"lg"}
            p={8}
            width={{ base: "full", lg: "2xl" }}
            flexDir={"row"}
            display={"flex"}
            justifyContent={"space-between"}
          >
            <Flex flex={0.9} mx={1}>
              <FormControl id="key">
                <Controller
                  control={control}
                  name="key"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <>
                      <Input
                        type="key"
                        placeholder="Digite a chave NF de 44 caracteres"
                        value={value || ""}
                        onChange={(value) =>
                          onChange(value.target.value.replace(/\D/g, ""))
                        }
                        onBlur={onBlur}
                        min={44}
                        max={44}
                      />
                      {errors && errors.key && (
                        <Badge colorScheme="red">{errors.key.message}</Badge>
                      )}
                    </>
                  )}
                />
              </FormControl>
            </Flex>
            <Flex flex={0.1} mx={1}>
              <Button
                bg={"primary.400"}
                color={"white"}
                _hover={{
                  bg: "primary.500",
                }}
                isDisabled={!isValid}
                onClick={search}
              >
                Pesquisar
              </Button>
            </Flex>
          </Box>

          {invoice && (
            <>
              <InvoiceDetails
                invoice={{
                  key: invoice.key,
                  natureOfOperation: invoice.natureOfOperation,
                  model: invoice.model,
                  serie: invoice.serie,
                  number: invoice.number,
                  issuer: invoice.issuer,
                  recipient: invoice.recipient,
                  products: invoice.products,
                }}
              />
              <Button
                bg={"primary.400"}
                color={"white"}
                _hover={{
                  bg: "primary.500",
                }}
                onClick={handleCancelInvoice}
              >
                Cancelar importação de nota fiscal
              </Button>
            </>
          )}
        </Stack>
      </Container>
    </>
  );
}
