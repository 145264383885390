import {
  Box,
  Container,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Company } from "../../../core/models/Company";
import companiesService from "../../../core/services/companies";
import { setLoader } from "../../../core/stores/reducers/Loader";
import Alert from "../../../core/utils/Alert";
import { formatCNPJ } from "../../../core/utils/FormatCNPJ";
export default function ListCompanies() {
  const [companies, setCompanies] = useState<Company[]>([] as Company[]);

  const dispatch = useDispatch();

  const findAll = useCallback(() => {
    dispatch(
      setLoader({
        isLoading: true,
      })
    );

    companiesService
      .findAll()
      .then((response) => {
        const { data } = response;
        setCompanies(data);
      })
      .catch(() => {
        Alert.danger({
          message: "Ocorreu um erro ao tentar buscar os dados das empresas",
        });
      })
      .finally(() => {
        dispatch(
          setLoader({
            isLoading: false,
          })
        );
      });
  }, [dispatch]);

  useEffect(() => {
    findAll();
  }, [findAll]);

  return (
    <>
      <Container maxW={"3xl"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <TableContainer>
            <Table variant="simple">
              <TableCaption>
                Lista de empresas cadastradas no sistema
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>CNPJ</Th>
                  <Th>Nome da Empresa</Th>
                </Tr>
              </Thead>
              <Tbody>
                {companies.map((company) => {
                  return (
                    <Tr key={company._id}>
                      <Td>{formatCNPJ(company.document)}</Td>
                      <Td>{company.name}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Container>
    </>
  );
}
