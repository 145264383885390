import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../models/User";
import { RootState } from "../../stores/index";

export interface SessionState {
  user: User | undefined;
  token: string | undefined;
}

const initialState: SessionState = {
  user: undefined,
  token: undefined,
};

const slice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<SessionState>) => ({
      ...state,
      ...action.payload,
    }),
  },
});

export const { setSession } = slice.actions;

export const selectSession = (state: RootState) => state.session;

export default slice.reducer;
