export enum AppType {
  Common = "common",
  Admin = "admin",
}

export interface App {
  _id: string;
  name: string;
  slug: string;
  type: AppType;
  createdAt: string;
}
