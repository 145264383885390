import { Flex, Spinner, Text } from "@chakra-ui/react";
import React from "react";
import { useSelector } from "react-redux";
import { selectLoader } from "../../core/stores/reducers/Loader";

const Loader: React.FC = () => {
  const { isLoading, text } = useSelector(selectLoader);

  if (isLoading)
    return (
      <Flex
        position={"fixed"}
        opacity={0.9}
        flex={1}
        width="full"
        height={"100vh"}
        justifyContent={"center"}
        alignItems="center"
        flexDirection="column" // Updated here to display items in a column
        backgroundColor={"white"}
        top={0}
        bottom={0}
        zIndex={99999}
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="secondary.200"
          color="primary.500"
          size="xl"
        />
        {text && (
          <Text fontSize="xl" mt={4} color="primary.500">
            {text}
          </Text>
        )}
      </Flex>
    );

  return null;
};

export default Loader;
