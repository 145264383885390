import * as yup from "yup";

const newInvoiceSchema = yup.object({
  key: yup.string().required(),
  type: yup.number().required(),
  natureOfOperation: yup.string().required(),
  model: yup.string().required(),
  serie: yup.string().required(),
  number: yup.string().required(),
  issuer: yup.object({
    document: yup.string().required(),
    name: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
  }),
  recipient: yup.object({
    document: yup.string().required(),
    name: yup.string().required(),
    city: yup.string().required(),
    state: yup.string().required(),
  }),
  products: yup.array().of(
    yup.object({
      name: yup.string().required(),
      quantity: yup.number().required(),
      commercializedBusinessUnit: yup.string().required(),
      unitValue: yup.number().required(),
      total: yup.number().required(),
      cfop: yup.number().required(),
    })
  ),
});

export default newInvoiceSchema;
