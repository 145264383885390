import ReactSelect, {
  Props as ReactSelectProps,
  StylesConfig,
} from "react-select";

interface MyOptionType {
  label: string;
  value: string;
}

const customStyles: StylesConfig<MyOptionType, false> = {
  option: (provided) => ({
    ...provided,
    textAlign: "left",
  }),
};

interface SelectProps extends ReactSelectProps<MyOptionType, false> {}

const Select: React.FC<SelectProps> = (props) => {
  return <ReactSelect styles={customStyles} {...props} isClearable />;
};

export default Select;
