"use client";

import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { NewProductRequest } from "../../../core/models/Product";
import productsService from "../../../core/services/products";
import { setLoader } from "../../../core/stores/reducers/Loader";
import Alert from "../../../core/utils/Alert";
import newProductSchema from "../../../core/validations/newProduct";

//Filepond
import { FilePond, registerPlugin } from "react-filepond";

import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

import { ActualFileObject } from "filepond";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import uploadsService from "../../../core/services/upload";

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

export default function NewProduct() {
  const dispatch = useDispatch();

  const filepondRef = useRef<FilePond>(null);

  const { control, handleSubmit, reset, setValue, getValues } =
    useForm<NewProductRequest>({
      resolver: yupResolver(newProductSchema),
      mode: "all",
    });

  const resetState = () => {
    setValue("pictureUrl", "");
  };

  const handleGetNameSuggestion = useCallback(
    (barcode: string) => {
      dispatch(
        setLoader({
          isLoading: true,
          text: "Buscando sugestão de produto",
        })
      );
      productsService
        .suggestNameBasedOnBarcode(barcode)
        .then(({ data }) => {
          const { name } = data;
          setValue("name", name);
        })
        .finally(() => {
          dispatch(
            setLoader({
              isLoading: false,
              text: undefined,
            })
          );
        });
    },
    [dispatch, setValue]
  );

  const handleBlur = useCallback(
    (
      event: React.FocusEvent<HTMLInputElement>,
      onBlur: (event: React.FocusEvent<HTMLInputElement>) => void
    ) => {
      const { barcode, name } = getValues();

      if (barcode && !name) {
        handleGetNameSuggestion(barcode);
      }

      if (onBlur) {
        onBlur(event);
      }
    },

    [getValues, handleGetNameSuggestion]
  );

  const create = handleSubmit((data: NewProductRequest) => {
    dispatch(
      setLoader({
        isLoading: true,
      })
    );
    productsService
      .create(data)
      .then(() => {
        Alert.success({
          message: "Produto criado com sucesso",
        });
        reset();
        filepondRef.current?.removeFiles();
      })
      .catch(() => {
        Alert.danger({
          message: "Ocorreu um erro ao tentar cadastrar o produto",
        });
      })
      .finally(() => {
        dispatch(
          setLoader({
            isLoading: false,
          })
        );
      });
  });

  const handleUploadFile = (file: ActualFileObject) => {
    dispatch(
      setLoader({
        isLoading: true,
        text: "Enviando sua imagem. Aguarde...",
      })
    );
    uploadsService
      .sendNewFile(file)
      .then(({ data }) => {
        const { url } = data;
        setValue("pictureUrl", url);
      })
      .catch(() => {
        setValue("pictureUrl", "");
      })
      .finally(() => {
        dispatch(
          setLoader({
            isLoading: false,
          })
        );
      });
  };

  return (
    <>
      <Container maxW={"3xl"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Crie um
            <br />
            <Text as={"span"} color={"primary.400"}>
              novo produto
            </Text>
          </Heading>

          <Box rounded={"lg"} bg={"white"} boxShadow={"lg"} p={8}>
            <Stack spacing={4}>
              <form onSubmit={create}>
                <FormControl id="barcode" mt={4}>
                  <FormLabel as="label">Código de barras do produto:</FormLabel>
                  <Controller
                    control={control}
                    name="barcode"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        type="barcode"
                        placeholder="Digite aqui o código de barras do produto"
                        value={value || ""}
                        onChange={(event) => {
                          onChange(
                            event.target.value.toUpperCase().replace(/\D/g, "")
                          );
                        }}
                        autoComplete="off"
                        onBlur={(event) => {
                          handleBlur(event, onBlur);
                        }}
                        required
                        autoFocus
                      />
                    )}
                  />
                </FormControl>

                <FormControl id="name" mt={4}>
                  <FormLabel as="label">Nome do produto:</FormLabel>
                  <Controller
                    control={control}
                    name="name"
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Input
                        type="name"
                        placeholder="Digite aqui o nome do seu produto"
                        value={value || ""}
                        onChange={(event) => {
                          onChange(event.target.value.toUpperCase());
                        }}
                        autoComplete="off"
                        onBlur={onBlur}
                        required
                      />
                    )}
                  />
                </FormControl>

                <FormControl as="fieldset" mt={4} mb={4}>
                  <FormLabel as="label">Foto do produto:</FormLabel>
                  <FilePond
                    allowMultiple={false}
                    maxFiles={1}
                    labelIdle='Arraste e solte um arquivo ou <span class="filepond--label-action">clique aqui para procurar</span>'
                    onaddfile={(_, item) => {
                      handleUploadFile(item.file);
                    }}
                    onremovefile={resetState}
                    ref={filepondRef}
                    allowImagePreview
                    acceptedFileTypes={["image/png", "image/jpeg"]}
                    maxFileSize="2MB"
                    required
                  />
                  <FormHelperText>
                    Você pode enviar aquivos do tipo <b>.png</b> ou <b>.jpg</b>.
                  </FormHelperText>
                </FormControl>

                <Stack spacing={10}>
                  <Button
                    bg={"primary.400"}
                    color={"white"}
                    _hover={{
                      bg: "primary.500",
                    }}
                    type="submit"
                  >
                    Salvar
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </>
  );
}
