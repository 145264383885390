import {
  Box,
  Button,
  Container,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { User } from "../../../core/models/User";
import usersService from "../../../core/services/user";
import { setLoader } from "../../../core/stores/reducers/Loader";
import Alert from "../../../core/utils/Alert";
import CompaniesTable from "./CompaniesTable";
import PermissionsTable from "./PermissonsTable";

export interface CurrentModalProps {
  user: User;
  table: "permissions" | "companies";
}

export default function ListUsers() {
  const [users, setUsers] = useState<User[]>([] as User[]);
  const [currentModalProps, setCurrentModalProps] =
    useState<CurrentModalProps | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const dispatch = useDispatch();

  const findAll = useCallback(() => {
    dispatch(
      setLoader({
        isLoading: true,
      })
    );

    usersService
      .findAll()
      .then((response) => {
        const { data } = response;
        setUsers(data);
      })
      .catch(() => {
        Alert.danger({
          message: "Ocorreu um erro ao tentar buscar os dados dos usuários",
        });
      })
      .finally(() => {
        dispatch(
          setLoader({
            isLoading: false,
          })
        );
      });
  }, [dispatch]);

  const handleResetPassword = useCallback(
    (user: User) => {
      dispatch(
        setLoader({
          isLoading: true,
        })
      );

      usersService
        .resetPassword(user._id)
        .then(({ data }) => {
          const { newPassword } = data;
          Alert.success({
            title: `Senha atualizada`,
            message: `<p>A senha do usuário <b>${user.name}</b> foi atualizada.</p>
            <p>Anote a senha: <b>${newPassword}</b></p>`,
          });
        })
        .catch(() => {
          Alert.danger({
            message: "Ocorreu um erro ao tentar atualizar a senha do usuário",
          });
        })
        .finally(() => {
          dispatch(
            setLoader({
              isLoading: false,
            })
          );
        });
    },
    [dispatch]
  );

  const handleOpenOrCloseModal = useCallback(() => {
    if (currentModalProps) {
      onOpen();
    } else {
      onClose();
    }
  }, [currentModalProps, onClose, onOpen]);

  useEffect(() => {
    findAll();
  }, [findAll]);

  useEffect(() => {
    handleOpenOrCloseModal();
  }, [handleOpenOrCloseModal]);

  return (
    <>
      {currentModalProps && (
        <Modal
          onClose={() => {
            setCurrentModalProps(null);
          }}
          size={"full"}
          isOpen={isOpen}
          closeOnEsc={false}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              <Box display={"flex"} flex={1} flexDir={"row"}>
                <Text fontWeight={400}>
                  {currentModalProps.table === "permissions"
                    ? "Permissões"
                    : "Empresas"}{" "}
                  do usuário:
                </Text>
                <Text fontWeight={700} ml={1}>
                  {currentModalProps.user.name}
                </Text>
              </Box>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {currentModalProps.table === "permissions" ? (
                <PermissionsTable
                  setCurrentModalProps={setCurrentModalProps}
                  user={currentModalProps.user}
                />
              ) : (
                <CompaniesTable
                  setCurrentModalProps={setCurrentModalProps}
                  user={currentModalProps.user}
                />
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                onClick={() => {
                  setCurrentModalProps(null);
                }}
              >
                Fechar
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Container maxW={"full"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Lista de usuários, empresas
            <br />
            <Text as={"span"} color={"primary.400"}>
              e permissões de apps
            </Text>
          </Heading>
          <TableContainer>
            <Table variant="striped">
              <TableCaption>
                Lista de usuários cadastradas no sistema
              </TableCaption>
              <Thead>
                <Tr>
                  <Th>Nome:</Th>
                  <Th>Email:</Th>

                  <Th>Empresas:</Th>
                  <Th>Permissões de apps:</Th>
                  <Th>Ações:</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map((user) => {
                  return (
                    <Tr key={user._id}>
                      <Td>{user.name}</Td>
                      <Td>{user.email}</Td>
                      <Td>
                        <Button
                          bg={"secondary.400"}
                          color={"white"}
                          _hover={{
                            bg: "secondary.500",
                          }}
                          onClick={() => {
                            setCurrentModalProps({
                              user,
                              table: "companies",
                            });
                          }}
                        >
                          Gerenciar empresas
                        </Button>
                      </Td>
                      <Td>
                        <Button
                          bg={"primary.400"}
                          color={"white"}
                          _hover={{
                            bg: "primary.500",
                          }}
                          onClick={() => {
                            setCurrentModalProps({
                              user,
                              table: "permissions",
                            });
                          }}
                        >
                          Ver permissões
                        </Button>
                      </Td>
                      <Td>
                        <Button
                          bg={"blue.400"}
                          color={"white"}
                          _hover={{
                            bg: "blue.500",
                          }}
                          onClick={() => {
                            handleResetPassword(user);
                          }}
                        >
                          Gerar nova senha
                        </Button>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        </Stack>
      </Container>
    </>
  );
}
