import {
  Box,
  Container,
  Heading,
  Link,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Invoice } from "../../../core/models/Invoice";
import { UserCompany } from "../../../core/models/User";
import invoicesService from "../../../core/services/invoices";
import usersService from "../../../core/services/user";
import { setLoader } from "../../../core/stores/reducers/Loader";
import { selectSession } from "../../../core/stores/reducers/Session";
import Alert from "../../../core/utils/Alert";
import Select from "../../../layout/components/Select";

export default function InvoiceReports() {
  const [userCompanies, setUserCompanies] = useState<UserCompany[]>(
    [] as UserCompany[]
  );
  const [invoices, setInvoices] = useState<Invoice[]>([] as Invoice[]);

  const [companyId, setCompanyId] = useState<string | null>(null);

  const options = userCompanies.map(({ company: { name, _id } }) => ({
    label: name,
    value: _id,
  }));

  const { user } = useSelector(selectSession);

  const dispatch = useDispatch();

  const findInvoices = useCallback(() => {
    if (companyId) {
      dispatch(
        setLoader({
          isLoading: true,
        })
      );

      invoicesService
        .findAll(companyId)
        .then((response) => {
          const { data } = response;
          setInvoices(data);
        })
        .catch(() => {
          Alert.danger({
            message:
              "Ocorreu um erro ao tentar buscar os dados das notas fiscais",
          });
        })
        .finally(() => {
          dispatch(
            setLoader({
              isLoading: false,
            })
          );
        });
    }
  }, [companyId, dispatch]);

  const findAllCompaniesByUser = useCallback(() => {
    dispatch(
      setLoader({
        isLoading: true,
      })
    );

    if (user) {
      usersService
        .findAllCompanies(user._id)
        .then((response) => {
          const { data } = response;
          setUserCompanies(data);
        })
        .catch(() => {
          Alert.danger({
            message: "Ocorreu um erro ao tentar buscar os dados das empresas",
          });
        })
        .finally(() => {
          dispatch(
            setLoader({
              isLoading: false,
            })
          );
        });
    }
  }, [user, dispatch]);

  useEffect(() => {
    findAllCompaniesByUser();
  }, [findAllCompaniesByUser]);

  useEffect(() => {
    findInvoices();
  }, [findInvoices]);

  return (
    <>
      <Container
        maxW={"full"}
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
          maxW={"3xl"}
          justifyContent={"center"}
          minWidth={"3xl"}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Relatório de notas
            <br />
            <Text as={"span"} color={"primary.400"}>
              fiscais importadas
            </Text>
          </Heading>

          <Select
            options={options}
            placeholder="Selecione uma empresa"
            onChange={(value) => {
              setCompanyId(value?.value ?? null);
            }}
          />
        </Stack>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          py={{ base: 20, md: 36 }}
          minWidth={"full"}
        >
          {invoices && invoices.length > 0 && (
            <TableContainer>
              <Table variant="striped">
                <Thead>
                  <Tr>
                    <Th>Chave:</Th>
                    <Th>Emissor:</Th>
                    <Th>Estoque destino:</Th>
                    <Th>Data importação:</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {invoices.map(({ key, issuer, createdAt, recipient }) => (
                    <Tr key={key}>
                      <Td>
                        <Link href={`/invoice/search?key=${key}`}>{key}</Link>
                      </Td>
                      <Td>{issuer.name}</Td>
                      <Td>{recipient.name}</Td>
                      <Td>{moment(createdAt).format("DD/MM/YYYY HH:mm:ss")}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Stack>
      </Container>
    </>
  );
}
