import axios from "axios";
import store from "../stores";

const api = axios.create({
  baseURL: process.env.REACT_APP_MAXIMO_API_BASE_URL,
  timeout: 10000,
});

api.interceptors.request.use(
  async function (config) {
    const noAuthRoutes = ["auth/login"];

    if (!noAuthRoutes.includes(config.url ?? "")) {
      if (
        typeof config.headers.Authorization !== "string" ||
        typeof config.headers.Authorization === "undefined"
      ) {
        let token = store.getState().session.token;

        if (token != null) config.headers.Authorization = `Bearer ${token}`;
      }
    }

    return config;
  },
  (err) => Promise.reject(err)
);

export default api;
