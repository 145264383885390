import { AxiosResponse } from "axios";
import { DeleteResourceResponse } from "../../models/GenericRequest";
import {
  NewUserCompanyRelationshipRequest,
  NewUserRequest,
  User,
  UserCompany,
  UserResetPasswordResponse,
  UserUpdatePasswordRequest,
} from "../../models/User";
import api from "../api";

const usersService = {
  updatePassword: async (
    data: UserUpdatePasswordRequest
  ): Promise<AxiosResponse<User>> => {
    const response: AxiosResponse<User> = await api.post<User>(
      "users/update-password",
      data
    );

    return response;
  },

  findAll: async (): Promise<AxiosResponse<User[]>> => {
    const response: AxiosResponse<User[]> = await api.get<User[]>("users");

    return response;
  },

  create: async (data: NewUserRequest): Promise<AxiosResponse<User>> => {
    const response: AxiosResponse<User> = await api.post<User>("users", data);

    return response;
  },

  findAllCompanies: async (
    userId: string
  ): Promise<AxiosResponse<UserCompany[]>> => {
    const response: AxiosResponse<UserCompany[]> = await api.get<UserCompany[]>(
      `users/${userId}/companies`
    );

    return response;
  },

  createCompanyRelationship: async (
    data: NewUserCompanyRelationshipRequest
  ): Promise<AxiosResponse<UserCompany>> => {
    const response: AxiosResponse<UserCompany> = await api.post<UserCompany>(
      "users/companies",
      data
    );

    return response;
  },
  removeCompanyRelationship: async (
    userCompanyRelationShipId: string
  ): Promise<AxiosResponse<DeleteResourceResponse>> => {
    const response: AxiosResponse<DeleteResourceResponse> =
      await api.delete<DeleteResourceResponse>(
        `users/companies/${userCompanyRelationShipId}`
      );

    return response;
  },

  resetPassword: async (
    userId: string
  ): Promise<AxiosResponse<UserResetPasswordResponse>> => {
    const response: AxiosResponse<UserResetPasswordResponse> =
      await api.put<UserResetPasswordResponse>(
        `users/${userId}/reset-password`
      );

    return response;
  },
};

export default usersService;
