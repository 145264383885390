import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { AxiosError } from "axios";
import { ActualFileObject } from "filepond";
import { useCallback, useEffect, useRef, useState } from "react";
import { FilePond } from "react-filepond";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import xml2js from "xml2js";
import { NewInvoiceRequest } from "../../../core/models/Invoice";
import { NfeProc } from "../../../core/models/NfeProc";
import invoicesService from "../../../core/services/invoices";
import { setLoader } from "../../../core/stores/reducers/Loader";
import Alert from "../../../core/utils/Alert";
import newInvoiceSchema from "../../../core/validations/newInvoice";
import InvoiceDetails from "../../../layout/components/Invoice/Details";

export default function ImportInvoice() {
  const [jsonContent, setJsonContent] = useState<NfeProc | null>(null);

  const dispatch = useDispatch();

  const filepondRef = useRef<FilePond>(null);

  const { handleSubmit, setValue, reset, watch } = useForm<NewInvoiceRequest>({
    resolver: yupResolver(newInvoiceSchema),
    mode: "all",
    defaultValues: {
      key: undefined,
    },
  });

  //Handle XML Import
  const convertXmlToJson = (xml: string): void => {
    const parser = new xml2js.Parser({ explicitArray: false });
    parser.parseString(xml, (err: Error | null, result: object) => {
      if (err) {
        console.error("Error converting XML to JSON:", err);
      } else {
        setJsonContent(result as NfeProc);
      }
    });
  };

  const handleFileChange = (file: ActualFileObject): void => {
    const selectedFile = file;

    if (selectedFile) {
      const fileReader = new FileReader();
      fileReader.onload = handleFileLoad;
      fileReader.readAsText(selectedFile);
    }
  };

  const handleFileLoad = (event: ProgressEvent<FileReader>): void => {
    const fileReader = event.target as FileReader;
    const content = fileReader.result as string;

    convertXmlToJson(content);
  };

  const resetState = () => {
    setJsonContent(null);
    reset();
  };

  const save = handleSubmit((data: NewInvoiceRequest) => {
    dispatch(
      setLoader({
        isLoading: true,
      })
    );
    invoicesService
      .create(data)
      .then(() => {
        Alert.success({
          message: "NF importada com sucesso",
        });
        resetState();
        filepondRef.current?.removeFiles();
      })
      .catch(() => {
        Alert.danger({
          message: "Houve uma falha ao salvar a NF",
        });
      })
      .finally(() => {
        dispatch(
          setLoader({
            isLoading: false,
          })
        );
      });
  });

  const findOne = useCallback(() => {
    if (jsonContent) {
      dispatch(
        setLoader({
          isLoading: true,
        })
      );

      const key = jsonContent.nfeProc.protNFe.infProt.chNFe;

      invoicesService
        .findByKey(key)
        .then((response) => {
          Alert.danger({
            message: "A nota fiscal selecionada já foi importada",
          });
          filepondRef.current?.removeFile();
        })
        .catch((error: AxiosError) => {
          const httpStatusCode = Number(error.response?.status);

          if (httpStatusCode === 404) {
            setValue("key", key);
            setValue("type", Number(jsonContent.nfeProc.NFe.infNFe.ide.tpNF));
            setValue(
              "natureOfOperation",
              jsonContent.nfeProc.NFe.infNFe.ide.natOp
            );
            setValue("model", jsonContent.nfeProc.NFe.infNFe.ide.mod);
            setValue("serie", jsonContent.nfeProc.NFe.infNFe.ide.serie);
            setValue("number", jsonContent.nfeProc.NFe.infNFe.ide.nNF);
            setValue("issuer", {
              document: jsonContent.nfeProc.NFe.infNFe.emit.CNPJ,
              name: jsonContent.nfeProc.NFe.infNFe.emit.xNome,
              city: jsonContent.nfeProc.NFe.infNFe.emit.enderEmit.xMun,
              state: jsonContent.nfeProc.NFe.infNFe.emit.enderEmit.UF,
            });
            setValue("recipient", {
              document: jsonContent.nfeProc.NFe.infNFe.dest.CNPJ,
              name: jsonContent.nfeProc.NFe.infNFe.dest.xNome,
              city: jsonContent.nfeProc.NFe.infNFe.dest.enderDest.xMun,
              state: jsonContent.nfeProc.NFe.infNFe.dest.enderDest.UF,
            });
            setValue(
              "products",
              jsonContent.nfeProc.NFe.infNFe.det.map((det, key) => ({
                commercializedBusinessUnit: det.prod.uCom,
                name: det.prod.xProd,
                quantity: Number(det.prod.qCom),
                total: Number(det.prod.vProd),
                unitValue: Number(det.prod.vUnCom),
                cfop: Number(det.prod.CFOP),
                pictureUrl: `${process.env.REACT_APP_MAXIMO_FRONTEND_URL}assets/noPicture.jpeg`,
              }))
            );
          } else {
            Alert.danger({
              message: "Ocorreu um erro ao processar a requisição",
            });
          }
        })
        .finally(() => {
          dispatch(
            setLoader({
              isLoading: false,
            })
          );
        });
    }
  }, [dispatch, jsonContent, setValue]);

  useEffect(() => {
    findOne();
  }, [findOne]);

  return (
    <>
      <Container maxW={"3xl"}>
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pt={{ base: 20, md: 36 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: "2xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
          >
            Importar
            <br />
            <Text as={"span"} color={"primary.400"}>
              nota fiscal
            </Text>
          </Heading>
          <FilePond
            allowMultiple={false}
            maxFiles={1}
            labelIdle='Arraste e solte seus arquivos ou <span class="filepond--label-action">clique aqui para procurar</span>'
            onaddfile={(_, item) => {
              handleFileChange(item.file);
            }}
            onremovefile={resetState}
            ref={filepondRef}
          />
        </Stack>
      </Container>
      {watch("key") && (
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          pt={{ base: 15, md: 27 }}
        >
          <InvoiceDetails
            invoice={{
              key: watch("key"),
              natureOfOperation: watch("natureOfOperation"),
              model: watch("model"),
              serie: watch("serie"),
              number: watch("number"),
              issuer: watch("issuer"),
              recipient: watch("recipient"),
              products: watch("products"),
            }}
          />
          <Stack
            spacing={10}
            maxW={"xl"}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
            pb={{ base: 10, md: 18 }}
          >
            <Button
              bg={"primary.400"}
              color={"white"}
              _hover={{
                bg: "primary.500",
              }}
              onClick={save}
              maxW={"1xl"}
            >
              Salvar
            </Button>
          </Stack>
        </Flex>
      )}
    </>
  );
}
